import Page from 'components/Page';
import { TableOfContents } from 'components/docs';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import useAccountIdRoute from 'hooks/useAccountIdRoute';
import { Breadcrumbs, LearnMoreAccordion, LinkButton } from 'subframe/index';

// ----------------------------------------------------------------------
interface DocumentSectionType {
  id: string;
  title: string;
  definition: JSX.Element;
  informalExplaination?: JSX.Element;
  example?: JSX.Element;
}

export default function ProductConcepts() {
  const { logEvent } = AnalyticsEventLogger();

  // Route to token not authorized accordion on troubleshooting page
  const troubleshootPageIgnoreRisk = useAccountIdRoute(
    '/orgs/:orgId/accounts/:accountId/troubleshoot-k8s-connector?problem=ignore-availability-risk',
  );

  const logAccordianExpandEvent = (
    isExpand: boolean,
    eventName: string,
    metaData: object,
  ) => {
    if (isExpand) {
      logEvent(eventName, metaData);
    }
  };
  const documentSections: DocumentSectionType[] = [
    {
      id: 'K8s-availability-platform',
      title: 'Chkk Kubernetes Availability Platform:',
      definition: (
        <>
          <span className="text-body font-body text-default-font">
            identifies and prioritizes Availability Risks before they cause
            incidents, tracks artifacts and assets across the fleet, Upgrade
            Plans to remediate risks and speed up upgrades. Platform teams use
            Chkk to execute safe upgrades, remediate availability risks, monitor
            unsupported versions, and get visibility into what artifacts are
            running where. With Chkk, teams learn from learn from others, don’t
            repeat mistakes, save time and effort, and get more done with fewer
            resources. Chkk’s platform offers 3 modules:
          </span>
          <div className="w-full items-start gap-1 pl-6 inline-block">
            <span className="text-body-bold font-body-bold text-default-font">
              Risk Ledger:{' '}
            </span>
            <span className="text-body font-body text-default-font">
              identifies, prioritizes, and addresses availability risks that
              cause errors, failures and disruptions.
            </span>
          </div>
          <div className="w-full items-start gap-1 pl-6 inline-block">
            <span className="text-body-bold font-body-bold text-default-font">
              Artifact Register:{' '}
            </span>
            <span className="text-body font-body text-default-font">
              discovers and maps all container images, registries, tools,
              control planes, add-ons, and applications across a Kubernetes
              fleet
            </span>
          </div>
          <div className="w-full items-start gap-1 pl-6 inline-block">
            <span className="text-body-bold font-body-bold text-default-font">
              Upgrade Copilot:{' '}
            </span>
            <span className="text-body font-body text-default-font">
              plans and executes cluster, add-on and application upgrades safely
              via Upgrade Plans.
            </span>
          </div>
        </>
      ),
    },
    {
      id: 'collective-learning',
      title: 'Collective Learning:',
      definition: (
        <span className="text-body font-body text-default-font">
          is Chkk’s core technology that codifies operational wisdom to prevent
          incidents, errors and disruptions.
        </span>
      ),
      informalExplaination: (
        <>
          <span className="text-body font-body text-default-font">
            Collective Learning has the following components today:
          </span>
          <div className="w-full grow shrink-0 basis-0 items-start gap-4 pl-3 inline-block">
            <span className="text-body-bold font-body-bold text-default-font">
              Availability Risk Signature (ARSig) Database:{' '}
            </span>
            <span className="text-body font-body text-default-font">
              discovers, curates and codifies new availability risks that have
              caused errors, failures and disruptions for other teams
            </span>
          </div>
          <div className="w-full grow shrink-0 basis-0 items-start gap-4 pl-3 inline-block">
            <span className="text-body-bold font-body-bold text-default-font">
              Knowledge Graph:{' '}
            </span>
            <span className="text-body font-body text-default-font">
              learns changes happening across hundreds of Kubernetes projects
              and models the impact of these changes to discover the safest
              upgrade paths
            </span>
          </div>
          <div className="w-full grow shrink-0 basis-0 items-start gap-4 pl-3 inline-block">
            <span className="text-body-bold font-body-bold text-default-font">
              Detectors:{' '}
            </span>
            <span className="text-body font-body text-default-font">
              track thousands of open source and vendor container images,
              operational tools, configurations and dependencies to catalog what
              is running in a Kubernetes fleet and where it is running.
            </span>
          </div>
          <div className="w-full grow shrink-0 basis-0 items-start gap-4 pl-3 inline-block">
            <span className="text-body-bold font-body-bold text-default-font">
              Chkk Research Team:{' '}
            </span>
            <span className="text-body font-body text-default-font">
              reviews and curates risks to make them actionable
            </span>
          </div>
        </>
      ),
    },
    {
      id: 'ar',
      title: 'Availability Risk (AR):',
      definition: (
        <span className="text-body font-body text-default-font">
          is a Defect, or a Misconfiguration, that can cause incidents. An
          Availability risk can also be an Unsupported Version, a Version
          Incompatibility, or a Deprecation of software or API versions that
          have reached, or are close to reaching, end of support or end of life.
        </span>
      ),
      informalExplaination: (
        <>
          <span className="text-body font-body text-default-font">
            Definition of an Availability Risk comprises:
          </span>
          <div className="w-full grow shrink-0 basis-0 items-start gap-4 pl-3 inline-block">
            <span className="text-body font-body text-default-font">
              The components and version numbers of the underlying Availability
              Risk.
            </span>
          </div>
          <div className="w-full grow shrink-0 basis-0 items-start gap-4 pl-3 inline-block">
            <span className="text-body font-body text-default-font">
              The trigger conditions which cause that Availability Risk to
              manifest as an end of life/support event or incidents.
            </span>
          </div>
        </>
      ),
      example: (
        <>
          <span className="text-body font-body text-default-font">
            (Defect) Traffic drops for 30 seconds on every NGINX Ingress
            Controller upgrade or restart due to transiently missing endpoint IP
            address.
          </span>
          <span className="text-body font-body text-default-font">
            (Unsupported Versions) K8s v1.19 is unsupported by Amazon EKS.
          </span>
        </>
      ),
    },

    {
      id: 'ar-sig',
      title: 'Availability Risk Signature (ARSig):',
      definition: (
        <span className="text-body font-body text-default-font">
          is the logic to detect the presence of a specific Availability Risk.
        </span>
      ),
      informalExplaination: (
        <span className="text-body font-body text-default-font">
          An ARSig is a program that detects whether a specific AR is present in
          a customer environment. It searches and matches for the AR pattern in
          your K8s clusters. ARSigs consider software versions (e.g. of K8s
          control plane and add-ons), cluster state (e.g. yaml configurations)
          and resource limits (e.g. IP addresses, CPU, memory) combining them
          with certain application behaviors (e.g. an increase in load) or a
          user action (e.g. an upgrade) to assess the risk of an incident.
        </span>
      ),
      example: (
        <span className="text-body font-body text-default-font">
          Check whether the environment contains any deployments with an
          nginx-ingress using version v1.4.7 to v1.10.3.
        </span>
      ),
    },

    {
      id: 'ar-sig-db',
      title: 'Availability Risk Signature Database (ARSig DB):',
      definition: (
        <span className="text-body font-body text-default-font">
          is a database containing all the ARSigs provided by Chkk’s Research
          Team, Chkk’s Users, and the K8s community at large.
        </span>
      ),
      informalExplaination: (
        <span className="text-body font-body text-default-font">
          Think of ARSig DB as the equivalent of the{' '}
          <LinkButton
            className="inline"
            onClick={() => {
              logEvent('concepts-arsigdb-cve-link', {
                link: 'https://cve.mitre.org/',
                label: 'CVE',
              });
              window.open('https://cve.mitre.org/', '_blank');
            }}
            variant="brand"
          >
            CVE database
          </LinkButton>{' '}
          in the security world–i.e. A database which contains all the ARSigs
          that are known to Chkk.
        </span>
      ),
    },

    {
      id: 'ar-sig-scan',
      title: 'Availability Risk Scan (ARSig Scan, shortform: Scan):',
      definition: (
        <>
          <span className="text-body font-body text-default-font">
            matches a running K8s environment with all relevant ARSigs in the
            ARSig DB, where relevance is determined by collecting the K8s
            environment’s metadata. Scanning for each ARSig comprises two
            distinct stages:
          </span>

          <div className="w-full items-start gap-1 pl-6 inline-block">
            <span className="text-body-bold font-body text-default-font">
              Contextualize:
            </span>
            <span className="text-body font-body text-default-font">
              {' '}
              checks if the components and versions involved in an ARSig are
              relevant in the environment.
            </span>
          </div>

          <div className="w-full items-start gap-1 pl-6 inline-block">
            <span className="text-body-bold font-body text-default-font">
              Test:
            </span>
            <span className="text-body font-body text-default-font">
              {' '}
              If the Contextualize stage confirms that the ARSig’s components
              and versions are present, A Test compares version numbers and
              trigger conditions to determine whether or not the ARSig is
              Detected in the environment. Most Tests present in Chkk are
              automated; some are still manual.
            </span>
          </div>
        </>
      ),
      informalExplaination: (
        <>
          <span className="text-body font-body text-default-font">
            ARSig Scans could be periodic (e.g. triggered every 12 hours),
            on-demand (triggered by a K8s user), or event driven (triggered by
            CI or CD Plugins). ARSigs Scans are naturally contextual and the
            relevant metadata is collected by Chkk Connectors, ensuring that
            Chkk only scans ARSigs that are relevant to a particular K8s
            environment. Here are some examples of ARSigs being irrelevant:
          </span>

          <div className="flex w-full grow shrink-0 basis-0 flex-col items-start gap-2 pl-6">
            <span className="text-body font-body text-default-font">
              {' '}
              1. Istio ARSigs are not relevant (and will not be scanned) because
              a K8s cluster isn’t using Istio service mesh.
            </span>
            <span className="text-body font-body text-default-font">
              {' '}
              2. Not running on EKS so any ARSigs with EC2 ALB are irrelevant.
            </span>
            <span className="text-body font-body text-default-font">
              {' '}
              3. ARSig only exists for K8s v1.22 when the running environment
              has K8s v1.23.
            </span>
          </div>
        </>
      ),
    },

    {
      id: 'scan-engines',
      title: 'Scan Engines:',
      definition: (
        <span className="text-body font-body text-default-font">
          scan and identify Availability Risks across different layers of
          Kubernetes and its cloud substrate. Multiple engines execute in
          tandem, each scanning for specific Availability Risks.
        </span>
      ),
    },

    {
      id: 'preverification-engines',
      title: 'Preverification Engines:',
      definition: (
        <span className="text-body font-body text-default-font">
          create a Digital Twin of a Kubernetes environment and execute a
          proposed Upgrade Plan, ensuring that all upgrade steps are preverified
          to execute without failures.
        </span>
      ),
    },

    {
      id: 'digital-twin',
      title: 'Digital Twin:',
      definition: (
        <>
          <span className="text-body font-body text-default-font">
            is a virtual replica of your infrastructure, designed to simulate
            its operations and interactions in a digital environment. There are
            four levels of a Digital Twin:
          </span>
          <div className="flex w-full items-start gap-1 pl-6">
            <span className="text-body font-body-bold text-default-font">
              Level 1: We run a digital replica of your Kubernetes cluster,
              using the same cluster version, node versions, and add-on versions
              with default config.
            </span>
          </div>
          <div className="flex w-full items-start gap-1 pl-6">
            <span className="text-body font-body-bold text-default-font">
              Level 2: Building on Level 1, this level includes the custom
              config of all the add-ons in addition to the elements of Level 1.
            </span>
          </div>
          <div className="flex w-full items-start gap-1 pl-6">
            <span className="text-body font-body-bold text-default-font">
              Level 3: We incorporate all aspects from Level 2, plus we
              introduce dummy applications that emulate the functionality of
              your infrastructure.
            </span>
          </div>
          <div className="flex w-full items-start gap-1 pl-6">
            <span className="text-body font-body-bold text-default-font">
              Level 4: This is the most advanced level, functioning as a staging
              environment. It is an exact replica of your cluster, including
              your applications, and runs within your cloud account.
            </span>
          </div>
        </>
      ),
    },

    {
      id: 'revalidation-mechanisms',
      title: 'Revalidation Mechanisms:',
      definition: (
        <span className="text-body font-body text-default-font">
          kickoff as soon as a new cluster is onboarded to Chkk. Revalidation
          spawns multiple Detectors described under Collective Learning. These
          Detectors use hash- and regex-based models to catalog what’s running
          in a Kubernetes fleet and where it’s running. Once these Detectors run
          to completion, a secondary mechanism does a second-pass on the Risk
          Ledger and Artifact Register to ensure that any false positives are
          removed. It can take up to 24 hours for the Revalidation mechanism to
          complete. You can also report false positives to Chkk using the
          Feedback button in the product.
        </span>
      ),
    },

    {
      id: 'lar',
      title: 'Latent Availability Risk (LAR):',
      definition: (
        <span className="text-body font-body text-default-font">
          is an ARSig that was Detected in your K8s cluster.
        </span>
      ),
      informalExplaination: (
        <span className="text-body font-body text-default-font">
          Chkk’s availability scan engine matches a running K8s environment with
          all relevant ARSigs in its ARSig DB. If the running K8s environment
          matches an ARSig then it becomes a Latent Availability Risk (LAR)
          which has the potential to cause incidents or make a software reach
          end of life/support. In computer science terms, think of an ARSig as a
          class (just a coded representation of a business logic) and a LAR as
          an object (an instantiation of a class which once created has its own
          lifecycle until it is resolved or abandoned). Chkk helps you manage
          (track, resolve, report) the entire lifecycle of LARs.
        </span>
      ),
      example: (
        <span className="text-body font-body text-default-font">
          For the NGINX ARSig example given above, if you’re using NGINX
          controller v1.10.3 to v1.18.0, you have a LAR as every restart of the
          NGINX controller will remove the hostname from load balancer Ingress
          entries, causing a traffic blackhole.
        </span>
      ),
    },

    {
      id: 'guardrails',
      title: 'Guardrails:',
      definition: (
        <span className="text-body font-body text-default-font">
          are rules and policies from a cloud provider, add-on vendor, K8s
          distribution or the community. The business decision on whether or not
          a Guardrail should be followed is made by cluster owner teams.
        </span>
      ),
    },

    {
      id: 'bp-sig',
      title: 'Best Practice Signature (BPSig):',
      definition: (
        <span className="text-body font-body text-default-font">
          is the logic to detect whether a particular Guardrail is Followed or
          Not Followed.
        </span>
      ),
    },
    {
      id: 'kba',
      title: 'Knowledge Base Article (KBA):',
      definition: (
        <>
          <span className="text-body font-body text-default-font">
            is a single webpage that provides a natural language explanation of
            an ARSig or BPSig with its corresponding severity, impact, trigger
            conditions, and mitigation/remediation. Wherever applicable, KBAs
            also contain code snippets to mitigate and/or remediate an ARSig or
            to follow a Guardrail. All ARSigs and BPSigs have unique KBAs
            associated with them.
          </span>
          <span className="text-body font-body text-default-font">
            There are two terms in KBAs that describe the state of detection of
            an Availability Risk:
          </span>
          <div className="w-full items-start gap-1 pl-6 inline-block">
            <span className="text-body-bold font-body text-default-font">
              Create:
            </span>
            <span className="text-body font-body text-default-font">
              {' '}
              which means that an Availability Risk exists in an environment but
              is not currently causing impact.
            </span>
          </div>
          <div className="w-full items-start gap-1 pl-6 inline-block">
            <span className="text-body-bold font-body text-default-font">
              Trigger:
            </span>
            <span className="text-body font-body text-default-font">
              {' '}
              which means that the Availability Risk has already started causing
              impact.
            </span>
          </div>
          <span className="text-body font-body text-default-font">
            KBAs support 3 actions for any detected Availability Risk or any
            Guardrail that is not followed:
          </span>
          <div className="w-full items-start gap-1 pl-6 inline-block">
            <span className="text-body-bold font-body text-default-font">
              Create Ticket:
            </span>
            <span className="text-body font-body text-default-font">
              {' '}
              creates a Jira ticket so you can track the Availability Risk or
              Guardrail in your own workspace and task queues.
            </span>
          </div>
          <div className="w-full items-start gap-1 pl-6 inline-block">
            <span className="text-body-bold font-body text-default-font">
              Mark:
            </span>
            <span className="text-body font-body text-default-font">
              {' '}
              If you don’t intend to fix an Availability Risk or Guardrail, it
              can be marked as False Positive, By Design, or Dev/Test Cluster.
              You can also provide a custom reason for marking a Risk.
            </span>
          </div>
          <div className="w-full items-start gap-1 pl-6 inline-block">
            <span className="text-body-bold font-body text-default-font">
              Ignore:{' '}
            </span>
            <span className="text-body font-body text-default-font">
              to inform your team that this Availability Risk or Guardrail is
              ignored and notifications will not be generated for this risk.
              Instead of ignoring all the resources affected by an Availability
              Risk or Guardrail, you can also ignore specific resources by
              putting annotations inside your IaC code. See the{' '}
              <LinkButton
                variant="brand"
                className="inline"
                onClick={() => {
                  logEvent('concepts-link', {
                    link: troubleshootPageIgnoreRisk,
                    label: 'KBA',
                  });
                  window.open(troubleshootPageIgnoreRisk, '_blank');
                }}
              >
                Troubleshooting page
              </LinkButton>{' '}
              to see examples on how to add these annotations.
            </span>
          </div>
        </>
      ),

      example: (
        <LinkButton
          variant="brand"
          onClick={() => {
            logEvent('concepts-link', {
              link: 'https://chkk.io/kba/chkk-k8s-800',
              label: 'KBA',
            });
            window.open('/kba/chkk-k8s-800', '_blank');
          }}
          data-cy="kba-standalone-link"
        >
          https://chkk.io/kba/chkk-k8s-800
        </LinkButton>
      ),
    },

    {
      id: 'preverified-upgrade-template',
      title: 'Preverified Upgrade Template:',
      definition: (
        <span className="text-body font-body text-default-font">
          is a sequence of stages and steps that represent the safest path to
          upgrade all clusters in an environment from one version of Kubernetes
          to another version. These steps are preverified to work without impact
          using metadata from a representative cluster in that environment.
          Upgrade Templates can instantiate Upgrade Plans for specific clusters.
        </span>
      ),
      informalExplaination: (
        <span className="text-body font-body text-default-font">
          Preverified Upgrade Templates are a sequence of stages and steps
          representing the safest upgrade path that Chkk has identified for all
          clusters in a specific environment. Examples of an environment include
          prod, staging, dev, sandbox, etc. Upgrade Templates expect that all
          clusters inside one environment, e.g. the staging environment, are
          similar in their control plane and Add-on versions and the main
          difference is in the cloud substrate (Node Groups/Pools) and the
          applications running on these clusters. You select one representative
          cluster from an environment and Chkk generates an Upgrade Template
          that can be used for all the clusters in that environment. Chkk
          discovers the safest upgrade path for your environment by detecting
          changes in downstream dependencies. Upgrade Templates provide
          contextualized release notes, breaking changes, and recommended
          versions of control plane, cloud substrate, and Add-ons. Upgrade
          Templates also provide a detailed sequence of upgrade steps which are
          preverified on a digital twin of your infrastructure. While generating
          the Upgrade Template, if Chkk detects any Add-ons that aren’t covered
          by Chkk, a mechanism is triggered to add the missing coverage before
          the Upgrade Template is generated and preverified. Every Upgrade
          Template is also audited by an expert before it is published. Upgrade
          Template generation can take up to 10 days so you should request them
          at least 2 weeks prior to the scheduled upgrades. You will see the
          “Waiting for Template” status in the Dashboard and will get notified
          when the Upgrade Template is published. Upgrade Templates are only
          supported for cluster upgrades.
        </span>
      ),
    },

    {
      id: 'cluster-upgrade-plan',
      title: 'Cluster Upgrade Plan:',
      definition: (
        <span className="text-body font-body text-default-font">
          contextualizes an Upgrade Template for a specific cluster, with
          cluster-specific attributes including cluster name, cluster region and
          Node Groups / Node Pools. Upgrade Plans also provide pre/postflight
          checks for the specific cluster.
        </span>
      ),
      informalExplaination: (
        <>
          <span className="text-body font-body text-default-font">
            A Cluster Upgrade Plan is an instantiation of an environment’s
            Upgrade Template for a specific cluster. Cluster Upgrade Plans are a
            sequence of steps representing the safest upgrade path that Chkk has
            identified for your clusters or Add-ons. An Upgrade Plan has the
            following lifecycle phases:
          </span>
          <div className="w-full grow shrink-0 basis-0 items-start gap-4 pl-3 inline-block">
            <span className="text-body-bold font-body-bold text-default-font">
              Instantiate:
            </span>
            <span className="text-body font-body text-default-font">
              {' '}
              can be done by clicking “Instantiate Upgrade Plan” in Chkk
              Dashboard.
            </span>
          </div>
          <div className="w-full grow shrink-0 basis-0 items-start gap-4 pl-3 inline-block">
            <span className="text-body-bold font-body-bold text-default-font">
              Generate:
            </span>
            <span className="text-body font-body text-default-font">
              {' '}
              This phase detects drift in clusters or Add-ons since the time of
              Upgrade Template creation. This drift is resolved before the
              Upgrade Plan is published. You will see the “Waiting for Plan”
              status in the Dashboard and will get notified when the Upgrade
              Plan is published. It can take 24-48 hours for an Upgrade Plan to
              be published.
            </span>
          </div>
          <div className="w-full grow shrink-0 basis-0 items-start gap-4 pl-3 inline-block">
            <span className="text-body-bold font-body-bold text-default-font">
              Execute:
            </span>
            <span className="text-body font-body text-default-font">
              {' '}
              where you execute the upgrade steps provided by the Plan. You will
              see the “In Progress” status in the Dashboard while you are
              executing these steps. Upgrade Plan moves to “Completed” phase
              when all the required steps of the Plan are marked as Done.
            </span>
          </div>
          <div className="w-full grow shrink-0 basis-0 items-start gap-4 pl-3 inline-block">
            <span className="text-body-bold font-body-bold text-default-font">
              Cancel:
            </span>
            <span className="text-body font-body text-default-font">
              {' '}
              if a user wants to cancel an Upgrade Plan they requested. You will
              still see the Plan with “Cancelled” status in the Dashboard.
            </span>
          </div>
        </>
      ),
    },

    {
      id: 'addon-upgrade-plan',
      title: 'Add-on Upgrade Plan:',
      definition: (
        <span className="text-body font-body text-default-font">
          provide preverified steps and stages to upgrade a specific Add-on.
          Currently, Upgrade Templates are not supported for Add-ons.
        </span>
      ),
    },

    {
      id: 'notifications',
      title: 'Notifications:',
      definition: (
        <span className="text-body font-body text-default-font">
          inform you about Availability Risks in your clusters and about Upgrade
          Templates and Plans published in your account. Chkk supports Email,
          Slack and in-app notifications.
        </span>
      ),
    },

    {
      id: 'cluster-metadata',
      title: 'Cluster Metadata:',
      definition: (
        <span className="text-body font-body text-default-font">
          is information about a K8s cluster including, but not limited to,
          information about K8s version, pods, deployments, add-ons,
          cloud-provided components (load balancers, DNS, etc.), and node
          configurations (machine type, kernel version, etc.). Secrets and
          config maps are redacted from the metadata by default. Other sensitive
          information can be redacted through the proxy configuration (see
          definition below).
        </span>
      ),
    },

    {
      id: 'blast-radius',
      title: 'Blast Radius:',
      definition: (
        <span className="text-body font-body text-default-font">
          refers to the potential impact and extent of damage that an error,
          failure or disruption can have within a system or infrastructure. It
          refers to the scope of the consequences and how far they can
          propagate.
        </span>
      ),
    },

    {
      id: 'chkk-dashboard',
      title: 'Chkk Dashboard:',
      definition: (
        <span className="text-body font-body text-default-font">
          is a UI to monitor ARSigs and BPSigs being scanned at different stages
          of a K8s cluster and manage the lifecycle of Availability Risks that
          are present in the cluster. Chkk Dashboard catalogs all the artifacts
          running in your clusters, and to create and use Upgrade Templates and
          Upgrade Plans.
        </span>
      ),
    },

    {
      id: 'chkk-integrations',
      title: 'Chkk Integrations:',
      definition: (
        <span className="text-body font-body text-default-font">
          are a set of pre-built connectors that integrate Chkk with existing
          DevOps tools such as GitHub, Jira, and Slack.
        </span>
      ),
    },

    {
      id: 'chkk-k8s-connector',
      title: 'Chkk K8s Connector:',
      definition: (
        <span className="text-body font-body text-default-font">
          runs in your Kubernetes cluster and gathers metadata required by Chkk
          to build Upgrade Plans and scan your cluster for relevant Availability
          Risks.
        </span>
      ),
    },

    {
      id: 'chkk-proxy-filter',
      title: 'Chkk Proxy Filter:',
      definition: (
        <span className="text-body font-body text-default-font">
          ensures that private or sensitive information never leaves K8s
          clusters. It achieves that by redacting all K8s secrets by default and
          providing a configuration interface that can be used to redact any
          information that is deemed private or sensitive by a customer.
        </span>
      ),
    },

    {
      id: 'chkk-operator',
      title: 'Chkk Operator:',
      definition: (
        <span className="text-body font-body text-default-font">
          is a Kubernetes Operator that can be used to install and operate K8s
          Connector in your Kubernetes environment.
        </span>
      ),
    },

    {
      id: 'k8s-cluster',
      title: 'Kubernetes Cluster:',
      definition: (
        <span className="text-body font-body text-default-font">
          is a logical grouping of compute instances that run your containers. A
          cluster consists of the K8s control plane (the instances that control
          how, when, and where your containers run), and the data plane (the
          instances where your containers run). In Chkk, each K8s cluster is a
          self-contained entity with its own ARSigs scans and Availability
          Risks. A K8s user onboards a cluster to Chkk by installing one or more
          Chkk agents (K8s Connector, CI Agent, CD Agent, or Runtime Agent) into
          the cluster.
        </span>
      ),
    },

    {
      id: 'k8s-addon',
      title: 'Kubernetes Add-on:',
      definition: (
        <span className="text-body font-body text-default-font">
          is software that provides supporting operational capabilities to K8s
          applications, but is not specific to the application. In a public
          cloud hosted K8s environment, this includes software like
          observability agents or K8s drivers that allow the cluster to interact
          with underlying cloud resources for networking, compute, and storage.
        </span>
      ),
    },

    {
      id: 'k8s-cron-job',
      title: 'Kubernetes CronJob:',
      definition: (
        <span className="text-body font-body text-default-font">
          is a one-line config in a crontab file. It is written in Cron format
          and runs a job periodically on a given schedule inside a specific
          cluster.
        </span>
      ),
    },

    {
      id: 'k8s-resource',
      title: 'Kubernetes Resource:',
      definition: (
        <span className="text-body font-body text-default-font">
          is an endpoint in the Kubernetes API that stores a collection of API
          objects of a certain kind; for example, the built-in pods resource
          contains a collection of Pod objects. A custom resource is an
          extension of the Kubernetes API that is not necessarily available in a
          default Kubernetes installation.
        </span>
      ),
    },

    {
      id: 'chkk-org',
      title: 'Chkk Organization:',
      definition: (
        <span className="text-body font-body text-default-font">
          is a group of Chkk accounts that are owned by a shared entity and are
          under common control. Commonly there is one Chkk Organization for each
          customer company or large department. Chkk Organization will be the
          outer boundary for enforcing policies on using Chkk (e.g. around
          permissions).
        </span>
      ),
    },

    {
      id: 'chkk-acct',
      title: 'Chkk Account:',
      definition: (
        <span className="text-body font-body text-default-font">
          is the core grouping of Chkk resources such as clusters, subscriptions
          and teams. Customers structure their footprint in these accounts, with
          a single account ranging from the entirety of a customer to a single
          environment of a single team (e.g. the dev clusters of the backend
          team). Onboarding your Cloud Accounts allows Chkk to detect
          Availability Risks that span across K8s and cloud substrate.
          Onboarding Cloud Accounts also allows Chkk to run preflight and
          postflight checks during Upgrade Plans.
        </span>
      ),
    },

    {
      id: 'chkk-team',
      title: 'Chkk Team:',
      definition: (
        <span className="text-body font-body text-default-font">
          is a group of users in an account. These users share a set of
          permissions to manage the resources in that account. Users might be a
          member of multiple teams in a single account which would lead them to
          have the union of permissions of all teams.
        </span>
      ),
    },
  ].filter((x) => !!x);

  const contents = documentSections.map((section) => {
    return { label: section.title.slice(0, -1), link: section.id };
  });

  return (
    <Page title="Concepts">
      <div className="flex h-full w-[calc(100%-64px)] flex-col items-start gap-6 pt-8 pb-0 px-3 bg-default-background ml-8">
        <Breadcrumbs>
          <Breadcrumbs.Item>Explore</Breadcrumbs.Item>
          <Breadcrumbs.Divider />
          <Breadcrumbs.Item active={true}>Concepts</Breadcrumbs.Item>
        </Breadcrumbs>
        <div className="flex w-full items-center justify-between">
          <span
            className="text-section-header font-section-header text-default-font"
            id="concepts-title"
          >
            Concepts
          </span>
        </div>
        <div className="flex w-full grow shrink-0 basis-0 items-start gap-6">
          <div className="flex grow shrink-0 basis-0 flex-col items-start gap-4 self-stretch">
            {documentSections.map((section) => {
              return (
                <div
                  className="flex w-full flex-col items-start gap-2 border border-solid border-neutral-border pt-2 pr-2 pb-2 pl-2"
                  key={section.id}
                  data-cy={section.id}
                >
                  <span
                    className="text-subheader font-subheader text-default-font pt-[45px] mt-[-45px]"
                    id={section.id}
                  >
                    {section.title}
                  </span>

                  {section.definition}
                  {section.informalExplaination && (
                    <LearnMoreAccordion
                      title="Wordy (Informal) Explanation"
                      onOpenChange={(e) => {
                        logAccordianExpandEvent(e, 'concepts-info-expand', {
                          title: `${section.title}`,
                          type: 'Wordy (Informal) Explanation',
                        });
                      }}
                    >
                      {section.informalExplaination}
                    </LearnMoreAccordion>
                  )}
                  {section.example && (
                    <LearnMoreAccordion
                      title="Example"
                      onOpenChange={(e) => {
                        logAccordianExpandEvent(e, 'concepts-info-expand', {
                          title: `${section.title}`,
                          type: 'Example',
                        });
                      }}
                    >
                      {section.example}
                    </LearnMoreAccordion>
                  )}
                </div>
              );
            })}
          </div>
          <TableOfContents pageName="concepts" contents={contents} />
        </div>
      </div>
    </Page>
  );
}
