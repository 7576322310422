'use client';
import * as SubframeCore from '@subframe/core';
import {
  createUpgradeTemplate,
  useListClusters,
  useListSubscriptions,
} from 'api/frontend';
import {
  Cluster,
  DeploymentStrategy,
  ListClustersResponse,
  ResolvedEntitlementFeaturesItem,
  UpgradeTemplate,
} from 'api/models';
import { ConsoleLoader } from 'components/Loader';
import { LockingWrapper } from 'components/LockingWrapper';
import Page from 'components/Page';
import { RouterLink } from 'components/RouterLink';

import { AtomicTooltip } from 'components/design-system';
import BaseTable from 'components/design-system/Table/BaseTable';
import { somethingWentWrong, toastAutoHideDuration } from 'constants/toasts';
import useAccountIdRoute from 'hooks/useAccountIdRoute';
import useUserAccountState from 'hooks/useUserAccountState';
import { useSnackbar } from 'notistack';
import { SyntheticEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useDebounce } from 'react-use';
import {
  Badge,
  Breadcrumbs,
  Button,
  CardIntegration,
  IconButton,
  RadioGroup,
} from 'subframe/index';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import styles from '../UpgradeTemplatesRequestTemplate.module.scss';
import { Searchbar } from 'components/design-system/Searchbar';
import { getClusterProvider, getClusterRegion } from 'utils/clusterStates';

function UpgradeTemplatesRequestTemplate() {
  const { logEvent } = AnalyticsEventLogger();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { account, currentOrganization } = useUserAccountState();
  const upgradeTemplatesBasePath = useAccountIdRoute(
    '/orgs/:orgId/accounts/:accountId/upgrades/templates',
  );
  const clustersBasePath = useAccountIdRoute(
    '/orgs/:orgId/accounts/:accountId/clusters',
  );
  //Subscriptions
  const { data: Subscriptions } = useListSubscriptions(
    currentOrganization?.slug,
    {},
    {
      request: { headers: { Authorization: `Bearer ${account?.token}` } },
    },
  );

  const { data } = useListClusters(
    { filter: ['status:active'] },
    {
      request: {
        headers: { Authorization: `Bearer ${account.token}` },
      },
    },
  );
  const realClusters = data
    ? (data as ListClustersResponse).data?.filter(
        (c) => c.internal_k8s_ref !== 'example-cluster-ref',
      )
    : [];
  const [selectedCluster, setSelectedCluster] = useState<Cluster>(
    searchParams.get('cluster_id')
      ? realClusters.filter((c) => c.id === searchParams.get('cluster_id'))[0]
      : ({} as Cluster),
  );
  const displayData = searchParams.get('cluster_id')
    ? realClusters.filter((c) => c.id === searchParams.get('cluster_id'))
    : realClusters
        .filter((c) => !!c.environment)
        .sort((a, b) => {
          return (a.environment || '') > (b.environment || '') ? 1 : -1;
        });
  const [searchCluster, setSearchCluster] = useState('');
  const [clustersToShow, setClustersToShow] = useState(displayData);
  const handleOnChange = (
    event: SyntheticEvent<Element, Event>,
    newValue: string,
  ) => {
    setSearchCluster(newValue);
  };
  useDebounce(
    () => {
      if (searchCluster) {
        logEvent('search-clusters-to-upgrade', { searchCluster });
      }
    },
    1000,
    [searchCluster],
  );
  useEffect(() => {
    // handle search
    if (searchCluster !== '') {
      const found = displayData.filter((item) =>
        item?.name?.toLowerCase().includes(searchCluster.toLowerCase()),
      );
      setClustersToShow(found);
    } else {
      setClustersToShow(displayData);

      // selected values based on search params
      setSelectedCluster(
        searchParams.get('cluster_id')
          ? realClusters.filter(
              (c) => c.id === searchParams.get('cluster_id'),
            )[0]
          : ({} as Cluster),
      );
    }
  }, [data, searchCluster]);

  const [selectedDeploymentStrategy, setSelectedDeploymentStrategy] =
    useState<DeploymentStrategy>();
  const [requestTemplateLoading, setRequestTemplateLoading] = useState(false);
  const requestUpgradeTemplate = async function (type: DeploymentStrategy) {
    if (requestTemplateLoading) {
      return;
    }
    setSelectedDeploymentStrategy(type);
    setRequestTemplateLoading(true);
    logEvent('upgrades-templates-strategy-selected', {
      upgrade_template_type: type,
    });
    if (selectedCluster === undefined || !selectedCluster.id) {
      enqueueSnackbar('Please select a cluster to create an Upgrade Template', {
        variant: 'error',
        autoHideDuration: toastAutoHideDuration,
      });
      setRequestTemplateLoading(false);
      return;
    }

    try {
      const createdUpgradeTemplate: UpgradeTemplate =
        await createUpgradeTemplate(
          {
            deployment_strategy: type || 'pick-for-me',
            environment: selectedCluster.environment || '',
            resources: {
              clusters: [selectedCluster],
            },
          },
          { headers: { Authorization: `Bearer ${account.token}` } },
        );
      logEvent('clusters-upgrade-template-created', {
        upgrade_template_type: type,
        clusterId: selectedCluster.id,
        templateId: createdUpgradeTemplate?.id,
        upgradeTemplateName: createdUpgradeTemplate?.name,
      });
      enqueueSnackbar(
        "Upgrade Template request successful. We'll notify you once it is ready. Please note that this process may take up to 10 days",
        {
          variant: 'success',
          autoHideDuration: toastAutoHideDuration,
        },
      );
      setRequestTemplateLoading(false);
      navigate(upgradeTemplatesBasePath);
    } catch (err) {
      enqueueSnackbar(
        somethingWentWrong.replace(
          '<action>',
          'requesting this Upgrade Template',
        ),
        {
          variant: 'error',
          autoHideDuration: toastAutoHideDuration,
        },
      );
      setRequestTemplateLoading(false);
    }
  };

  const showTemplateLock = Subscriptions
    ? !Subscriptions?.total_entitlement?.features?.includes(
        'request_upgrade_template',
      )
    : true;

  return (
    <Page title="Cluster Upgrade Templates">
      <div
        style={{ marginLeft: '32px', width: 'calc(100% - 32px' }}
        className={styles['pageContents']}
      >
        <Breadcrumbs>
          <Breadcrumbs.Item>Upgrades</Breadcrumbs.Item>
          <Breadcrumbs.Divider />
          <RouterLink to={upgradeTemplatesBasePath}>
            <Breadcrumbs.Item>Upgrade Templates</Breadcrumbs.Item>
          </RouterLink>
          <Breadcrumbs.Divider />
          <Breadcrumbs.Item active={true}>Request Template</Breadcrumbs.Item>
        </Breadcrumbs>
        <div className={styles['header']}>
          <div className={styles['stack-b7e0a42a']}>
            <div className={styles['stack-f097a856']}>
              <div className={styles['stack']}>
                <span className={styles['sectionHeaderText']}>
                  Request Preverified Upgrade Template
                </span>
                <span className={styles['text-1027b3b1']}>
                  Select a representative cluster to get an Upgrade Template for
                  an environment
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className={styles['selectionHeader']}>
          <div className={styles['stack-99f7121c']}>
            <span className={styles['subheaderText']}>
              Select a Representative Cluster for an environment
            </span>
            <AtomicTooltip tooltipContent="An Upgrade Template can be used to instantiate Upgrade Plans for clusters in the same environment.  Only clusters with environment specified can be selected.">
              <SubframeCore.Icon name="FeatherInfo" style={{ width: '14px' }} />
            </AtomicTooltip>
          </div>
          {displayData.length && !searchParams.get('cluster_id') ? (
            <Searchbar onChange={handleOnChange} />
          ) : (
            <></>
          )}
        </div>
        {data === undefined && <ConsoleLoader />}
        {data !== undefined && realClusters.length === 0 && (
          <div className={styles['stack-e673aaa8']}>
            <span className={styles['bodyText']}>
              No clusters onboarded so far. Click here to onboard your first
              cluster
            </span>
            <RouterLink to={`${clustersBasePath}/new`}>
              <Button
                size="small"
                icon="FeatherPlus"
                onClick={() =>
                  logEvent(
                    'upgrades-templates-create-onboard-cluster-btn-click',
                  )
                }
              >
                Add Cluster
              </Button>
            </RouterLink>
          </div>
        )}
        {data !== undefined && realClusters.length > 0 && (
          <div className={styles['stack-6aad2ee1']}>
            <BaseTable
              settings={{
                localStorageKey: 'create_upgrade_templates_table',
                dataCyPrefix: 'upgrades-create-template',
              }}
              data={clustersToShow}
              noMatchingData={Boolean(
                displayData.length && !clustersToShow.length,
              )}
              onRowClick={(cluster) => {
                setSelectedCluster(cluster);
              }}
              emtpyState={
                <div className="w-full flex-col gap-[8px] items-center justify-center flex [font:var(--body)]">
                  <span>
                    No Environment set for Clusters. Cluster Environment is
                    required for Upgrade Templates.
                  </span>
                  <div className={styles['setEnvironmentMessage']}>
                    <RouterLink
                      className="hoverable-link text-body"
                      to={useAccountIdRoute(
                        `/orgs/:orgId/accounts/:accountId/clusters`,
                      )}
                    >
                      Set Environment
                    </RouterLink>
                    {' or '}
                    <RouterLink
                      className="hoverable-link text-body"
                      to={useAccountIdRoute(
                        `/orgs/:orgId/accounts/:accountId/clusters/new`,
                      )}
                    >
                      Add Cluster
                    </RouterLink>
                  </div>
                </div>
              }
              columns={[
                {
                  id: 'cluster',
                  title: 'Cluster',
                  titleStyle: { width: '35%' },
                  cellType: 'cell',
                  render: (cluster) => {
                    return (
                      <RadioGroup>
                        <AtomicTooltip tooltipContent={cluster.name}>
                          <RadioGroup.Option
                            className="max-w-[256px] whitespace-nowrap overflow-hidden overflow-ellipsis"
                            checked={selectedCluster?.id === cluster.id}
                            label={cluster.name}
                            value={cluster.id}
                          />
                        </AtomicTooltip>
                      </RadioGroup>
                    );
                  },
                },
                {
                  id: 'environment',
                  title: 'Environment',
                  cellType: 'cell',
                  titleStyle: { width: '25%' },
                  render: (cluster) => {
                    return (
                      <AtomicTooltip tooltipContent={cluster.environment}>
                        <Badge
                          variant="neutral"
                          style={{
                            maxWidth: '200px',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                          }}
                        >
                          {cluster.environment}
                        </Badge>
                      </AtomicTooltip>
                    );
                  },
                },
                {
                  id: 'version',
                  title: 'Current Version',
                  titleStyle: { width: '20%' },
                  cellType: 'cell',
                  render: (cluster) => {
                    return (
                      <span className={styles['text-21410235']}>
                        {cluster.version}
                      </span>
                    );
                  },
                },
                {
                  id: 'provider-region',
                  title: 'Provider : Region',
                  titleStyle: { width: '20%' },
                  cellType: 'cell',
                  render: (cluster) => {
                    return (
                      <span className={styles['text-e99f62a6']}>
                        {`${getClusterProvider(cluster)} : ${getClusterRegion(
                          cluster,
                        )}`}
                      </span>
                    );
                  },
                },
              ]}
              actions={[]}
            />
          </div>
        )}
        {/* Only show cards if clusters are available to create upgrade template */}
        {displayData.length ? (
          <>
            <span className={styles['subheaderText']}>Select Upgrade Type</span>
            {/* TODO: implement better subscription locking */}
            <div className={styles['stack-9e4c976b']}>
              <LockingWrapper
                feature={
                  ResolvedEntitlementFeaturesItem.request_upgrade_template
                }
                tooltipText={
                  displayData?.length
                    ? undefined //default, handled in LockingWrapper
                    : 'Add a cluster to get Preverified Upgrade Template'
                }
                showLock={showTemplateLock}
                defaultAction={() => requestUpgradeTemplate('pick-for-me')}
              >
                <CardIntegration
                  cardTitle="Pick for me"
                  text="Let Chkk pick the safest upgrade path for you"
                  badge={<Badge variant="success">Recommended</Badge>}
                  loading={
                    requestTemplateLoading &&
                    selectedDeploymentStrategy === 'pick-for-me'
                  }
                  logo={
                    <img
                      className={styles['image']}
                      src="https://res.cloudinary.com/subframe/image/upload/v1711487219/uploads/2/iyvh079rw3f8advhh2xl.png"
                    />
                  }
                />
              </LockingWrapper>
              <LockingWrapper
                feature={
                  ResolvedEntitlementFeaturesItem.request_upgrade_template
                }
                tooltipText={
                  displayData?.length
                    ? undefined //default, handled in LockingWrapper
                    : 'Add a cluster to get Preverified Upgrade Template'
                }
                showLock={showTemplateLock}
                defaultAction={() => requestUpgradeTemplate('in-place')}
              >
                <CardIntegration
                  cardTitle="In Place"
                  text="Template to upgrade the cluster , nodes and add-ons in place"
                  badge={
                    <AtomicTooltip tooltipContent="In Place Upgrade Templates ensure that k8s infrastructure resources (clusters, nodes, add ons) are not deleted during the upgrade. In Place templates are suitable for clusters with stateful applications.">
                      <SubframeCore.Icon
                        name="FeatherInfo"
                        style={{ width: '14px' }}
                      />
                    </AtomicTooltip>
                  }
                  loading={
                    requestTemplateLoading &&
                    selectedDeploymentStrategy === 'in-place'
                  }
                  logo={<IconButton icon="FeatherPackageCheck" />}
                />
              </LockingWrapper>
              <LockingWrapper
                feature={
                  ResolvedEntitlementFeaturesItem.request_upgrade_template
                }
                tooltipText={
                  displayData?.length
                    ? undefined //default, handled in LockingWrapper
                    : 'Add a cluster to get Preverified Upgrade Template'
                }
                showLock={showTemplateLock}
                defaultAction={() => requestUpgradeTemplate('blue-green')}
              >
                <CardIntegration
                  cardTitle="Blue Green"
                  text="Template to create a new cluster with the desired version and move apps and traffic to it"
                  badge={
                    <AtomicTooltip tooltipContent="Blue Green Upgrade Templates create a new (Blue) cluster with a replica of the existing (Green) cluster's k8s infrastructure resources (clusters, nodes, add ons). Applications are then moved from the Blue to the Green cluster. Blue-Green templates are suitable for clusters with stateless applications.">
                      <SubframeCore.Icon
                        name="FeatherInfo"
                        style={{ width: '14px' }}
                      />
                    </AtomicTooltip>
                  }
                  loading={
                    requestTemplateLoading &&
                    selectedDeploymentStrategy === 'blue-green'
                  }
                  logo={<IconButton icon="FeatherFolders" />}
                />
              </LockingWrapper>
            </div>
            <div className={styles['stack-cd9916be']}>
              <LockingWrapper
                feature={
                  ResolvedEntitlementFeaturesItem.request_upgrade_template
                }
                tooltipText={
                  displayData?.length
                    ? undefined //default, handled in LockingWrapper
                    : 'Add a cluster to get Preverified Upgrade Template'
                }
                showLock={showTemplateLock}
                defaultAction={() => requestUpgradeTemplate('rolling')}
              >
                <CardIntegration
                  cardTitle="Rolling"
                  text="Template to create new nodes without recycling the cluster"
                  badge={
                    <AtomicTooltip tooltipContent="Rolling Upgrade Templates recycle all the nodes, but do not delete clusters or add ons. Rolling Upgrades follow a staggered pattern that gradually replaces instances of the existing environment with updated versions.">
                      <SubframeCore.Icon
                        name="FeatherInfo"
                        style={{ width: '14px' }}
                      />
                    </AtomicTooltip>
                  }
                  loading={
                    requestTemplateLoading &&
                    selectedDeploymentStrategy === 'rolling'
                  }
                  logo={<IconButton icon="FeatherBoxes" />}
                />
              </LockingWrapper>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </Page>
  );
}

export default UpgradeTemplatesRequestTemplate;
