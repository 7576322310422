import { useState } from 'react';
import { IconButton } from 'subframe/components/IconButton';
import { ActivityFeedbackFrame, TextArea } from 'subframe/index';
import { Button } from 'subframe/components/Button';
import { Dialog } from 'subframe/components/Dialog';
import styles from './UpgradesSkipStepDialog.module.scss';
import { Account } from 'models/account';
import { UpgradePlan } from 'api/models';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider from 'components/FormProvider';
import { updateUpgradePlanStageStep } from 'api/frontend';
import * as Sentry from '@sentry/browser';
import { useSnackbar } from 'notistack';
import { somethingWentWrong, toastAutoHideDuration } from 'constants/toasts';
import { AtomicTooltip } from 'components/design-system';
import { isPlanActionDisabled } from '../helpers/UpgradesHelpers';

export interface UpgradesUndoSkipStepDialogProps {
  account: Account;
  upgrade: UpgradePlan;
  stageNumber: number;
  stepNumber: number;
  onSuccess: () => void;
  isTemplate?: boolean;
}

export default function UpgradesUndoSkipStepDialog(
  props: UpgradesUndoSkipStepDialogProps,
) {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const undoSkipReasonSchema = Yup.object().shape({
    comment: Yup.string()
      .max(500, 'Comments can be up to 500 characters long')
      .optional(),
  });

  const defaultValues = {
    comment: '',
  };

  const methods = useForm({
    resolver: yupResolver(undoSkipReasonSchema),
    defaultValues: defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    getValues,
    reset,
    clearErrors,
  } = methods;

  const onSubmit = async (data: { comment: string }) => {
    setLoading(true);

    try {
      await updateUpgradePlanStageStep(
        props.upgrade.id,
        props.stageNumber.toFixed(),
        props.stepNumber.toFixed(),
        {
          status: 'in-progress',
          status_details: {
            comment: data.comment,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${props.account.token}`,
          },
        },
      );
      enqueueSnackbar('Upgrade Step was unskipped successfully', {
        variant: 'success',
        autoHideDuration: toastAutoHideDuration,
      });
      setDialogOpen(false);
      props.onSuccess();
    } catch (error) {
      Sentry.captureException(error);
      enqueueSnackbar(
        somethingWentWrong.replace('<action>', 'unskipping this Step'),
        { variant: 'error', autoHideDuration: toastAutoHideDuration },
      );
    } finally {
      setLoading(false);
    }
  };

  const resetToDefault = () => {
    reset(defaultValues);
  };
  if (props.isTemplate) {
    return <></>;
  }

  return (
    <>
      <ActivityFeedbackFrame variant={'default'}>
        {isPlanActionDisabled(props.upgrade.status) ? (
          <AtomicTooltip
            tooltipContent={`Action not allowed on ${props.upgrade.status} Upgrade Plan`}
          >
            <Button
              variant="brand-tertiary"
              size="medium"
              icon="FeatherUndo"
              style={{ display: 'flex', pointerEvents: 'all' }}
              disabled={true}
            >
              Revert
            </Button>
          </AtomicTooltip>
        ) : (
          <Button
            variant="brand-tertiary"
            size="medium"
            icon="FeatherUndo"
            style={{ display: 'flex', pointerEvents: 'all' }}
            onClick={(event) => {
              clearErrors();
              resetToDefault();

              setDialogOpen(true);
              event.preventDefault();
            }}
          >
            Revert
          </Button>
        )}
      </ActivityFeedbackFrame>

      <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
        <Dialog.Content className="w-112">
          <FormProvider
            id={'skip-step-dialog-form'}
            methods={methods}
            onSubmit={handleSubmit(onSubmit)}
            style={{ width: '100%' }}
          >
            <div className={styles['stack-e7cf0c04']}>
              <div className={styles['stack-50ff0504']}>
                <div className={styles['stack-c16dcb77']}>
                  <span className={styles['text']}>
                    Don&apos;t Skip this step
                  </span>
                  <IconButton
                    icon="FeatherX"
                    size="medium"
                    onClick={(event) => {
                      setDialogOpen(false);
                      event.preventDefault();
                    }}
                    disabled={loading}
                  />
                </div>
              </div>
              <div className={styles['stack-60149222']}>
                <TextArea
                  className="h-auto w-full flex-[0_0_auto]"
                  label="Comments (optional)"
                  helpText={errors.comment?.message}
                  error={methods.getFieldState('comment').invalid}
                  style={{ height: '5lh' }}
                >
                  <TextArea.Input
                    className="h-full min-h-[48px] w-full flex-[1_0_0]"
                    disabled={loading}
                    placeholder={
                      getValues('comment') ||
                      'Why do you think this step should not be Skipped?'
                    }
                    {...methods.register('comment')}
                  />
                </TextArea>
              </div>
              <div className={styles['stack-d3f36edb']}>
                <Button
                  size="large"
                  type="submit"
                  variant="brand-primary"
                  icon="FeatherSkipForward"
                  loading={isSubmitting || loading}
                  disabled={isSubmitting || loading}
                >
                  Undo Skip
                </Button>
              </div>
            </div>
          </FormProvider>
        </Dialog.Content>
      </Dialog>
    </>
  );
}
