import * as SubframeCore from '@subframe/core';
import dotMenuStyles from './DotMenu.module.scss';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Table } from 'subframe/index';
import { Element, Text } from 'react-markdown/lib/ast-to-react';
import { CopyToClipboard } from 'components/design-system/CopyToClipboard';

interface UpgradePlanStepMarkdownProps {
  content: string;
  className?: string;
}

export function UpgradePlanStepMarkdown(props: UpgradePlanStepMarkdownProps) {
  return (
    <Markdown
      remarkPlugins={[remarkGfm]}
      className={props.className}
      allowedElements={[
        'p',
        'strong',
        'em',
        'code',
        'a',
        'br',
        'li',
        'ul',
        'ol',
        'h1',
        'h2',
        'h3',
        'h4',
        'h5',
        'blockquote',
        'pre',
        'hr',
        'table',
        'th',
        'tr',
        'thead',
        'tbody',
        'td',
      ]}
      components={{
        // eslint-disable-next-line react/no-unstable-nested-components
        p: ({ node, ...props }) => (
          <div
            className={dotMenuStyles['bodyText']}
            style={{ whiteSpace: 'pre-wrap', width: '100%' }}
            {...props}
          />
        ),
        // eslint-disable-next-line react/no-unstable-nested-components
        h1: ({ node, children }) => (
          <SubframeCore.Text
            variant="section-header"
            style={{ whiteSpace: 'pre-wrap' }}
          >
            {children}
          </SubframeCore.Text>
        ),
        // eslint-disable-next-line react/no-unstable-nested-components
        h2: ({ node, children }) => (
          <SubframeCore.Text
            variant="subheader"
            style={{ whiteSpace: 'pre-wrap' }}
          >
            {children}
          </SubframeCore.Text>
        ),
        // From now on the headers in the Step Content will start
        // from 3 since 1 & 2 are reserved for Stage & Step.
        // eslint-disable-next-line react/no-unstable-nested-components
        h3: ({ node, children, ...props }) => (
          <SubframeCore.Text
            variant="header"
            style={{ whiteSpace: 'pre-wrap' }}
          >
            {children}
          </SubframeCore.Text>
        ),
        // eslint-disable-next-line react/no-unstable-nested-components
        h4: ({ node, children, ...props }) => (
          <SubframeCore.Text
            variant="subheader"
            style={{ whiteSpace: 'pre-wrap' }}
          >
            {children}
          </SubframeCore.Text>
        ),
        // eslint-disable-next-line react/no-unstable-nested-components
        h5: ({ node, children, ...props }) => (
          <SubframeCore.Text
            variant="body-bold"
            style={{ whiteSpace: 'pre-wrap' }}
          >
            {children}
          </SubframeCore.Text>
        ),
        // eslint-disable-next-line react/no-unstable-nested-components
        br: ({ node, ...props }) => <span {...props} />,
        // eslint-disable-next-line react/no-unstable-nested-components
        strong: ({ node, ...props }) => (
          <span className={dotMenuStyles['bodyBoldText']} {...props} />
        ),
        // eslint-disable-next-line react/no-unstable-nested-components
        em: ({ node, ...props }) => (
          <span
            style={{
              fontStyle: 'italic',
            }}
            className={dotMenuStyles['bodyText']}
            {...props}
          />
        ),
        // eslint-disable-next-line react/no-unstable-nested-components
        blockquote: ({ node, children, ...props }) => (
          <div style={{ borderLeft: 'var(--gray-border)', padding: '8px' }}>
            <SubframeCore.Text style={{ whiteSpace: 'pre-wrap' }}>
              {children}
            </SubframeCore.Text>
          </div>
        ),
        // eslint-disable-next-line react/no-unstable-nested-components
        code: ({ node, inline, className, children, ...props }) => {
          if (inline) {
            // Handle inline code
            return (
              <code
                className="bg-neutral-100 border border-solid border-neutral-border font-monospace-body rounded py-0 px-[2px]"
                {...props}
              >
                {children}
              </code>
            );
          } else {
            // Handle code blocks
            const text = String(children).replace(/\n$/, '');
            return <CopyToClipboard text={text} />;
          }
        },
        // eslint-disable-next-line react/no-unstable-nested-components
        a: ({ node, href, ...props }) => {
          // patch up the href to be easier to use
          let dest = href;
          if (
            dest &&
            !dest.startsWith('http://') &&
            !dest.startsWith('https://')
          ) {
            dest = `https://${dest}`;
          }
          return (
            <a
              style={{ textDecoration: 'none' }}
              target="_blank"
              rel="noreferrer"
              href={dest}
            >
              <span className={dotMenuStyles['text-a6a5962e']} {...props} />
            </a>
          );
        },
        // eslint-disable-next-line react/no-unstable-nested-components
        ul: ({ node, ...props }) => (
          <ul className={dotMenuStyles['unordered-list']} {...props} />
        ),
        // eslint-disable-next-line react/no-unstable-nested-components
        ol: ({ node, ...props }) => (
          <ol className={dotMenuStyles['ordered-list']} {...props} />
        ),
        // eslint-disable-next-line react/no-unstable-nested-components
        li: ({ node, children, ...props }) => <li {...props}>{children}</li>,
        // eslint-disable-next-line react/no-unstable-nested-components
        pre: ({ node, children }) => {
          if (
            node.children.find(
              (child) => child.type === 'element' && child.tagName === 'code',
            )
          ) {
            return <>{children}</>;
          } else {
            return (
              <SubframeCore.Text
                variant="monospace-body"
                style={{
                  display: 'block',
                  whiteSpace: 'pre-wrap',
                  border: 'var(--gray-border)',
                  padding: '8px',
                  background: 'var(--neutral-50)',
                }}
              >
                {children}
              </SubframeCore.Text>
            );
          }
        },
        // eslint-disable-next-line react/no-unstable-nested-components
        hr: ({ node, ...props }) => {
          return (
            <hr
              style={{ border: 'var(--gray-border)', width: '99%' }}
              {...props}
            />
          );
        },
        // eslint-disable-next-line react/no-unstable-nested-components
        table: ({ node, children }) => {
          const thead: Element | undefined = node.children.find(
            (child) => child.type === 'element' && child.tagName === 'thead',
          ) as Element | undefined;
          const theadTr: Element | undefined = thead?.children.find(
            (child) => child.type === 'element' && child.tagName === 'tr',
          ) as Element | undefined;
          const headers = theadTr?.children
            .filter(
              (child) => child.type === 'element' && child.tagName === 'th',
            )
            .map((child) =>
              (child as Element).children[0].type === 'text'
                ? ((child as Element).children[0] as Text).value
                : '',
            );
          return (
            <Table
              header={
                <Table.HeaderRow>
                  {headers?.map((header, idx) => (
                    <Table.HeaderCell key={idx}>{header}</Table.HeaderCell>
                  ))}
                </Table.HeaderRow>
              }
            >
              {children}
            </Table>
          );
        },
        // eslint-disable-next-line react/no-unstable-nested-components
        thead: ({ node, children }) => {
          return <>{children}</>;
        },
        // eslint-disable-next-line react/no-unstable-nested-components
        tbody: ({ node, children }) => {
          return <>{children}</>;
        },
        // eslint-disable-next-line react/no-unstable-nested-components
        tr: ({ node, ...props }) => {
          if (
            node.children.find(
              (child) => child.type === 'element' && child.tagName === 'th',
            )
          ) {
            return <></>;
          }
          return <Table.Row {...props} />;
        },
        // eslint-disable-next-line react/no-unstable-nested-components
        td: ({ node, children }) => {
          if (node.children.length === 0) {
            return <Table.Cell> </Table.Cell>;
          } else {
            return <Table.Cell>{children}</Table.Cell>;
          }
        },
      }}
    >
      {props.content}
    </Markdown>
  );
}
