import {
  createUpgradeTemplateStageStepComment,
  deleteUpgradePlanStageStepLastComment,
  deleteUpgradeTemplateStageStepLastComment,
  updateUpgradeTemplate,
  useListUpgradeTemplateStageStepComments,
} from 'api/frontend';
import { UpgradeStage, UpgradeStageStep, UpgradeTemplate } from 'api/models';
import useUserAccountState from 'hooks/useUserAccountState';
import { useSnackbar } from 'notistack';
import * as Sentry from '@sentry/browser';
import {
  Accordion,
  Avatar,
  Button,
  ChatList,
  IconButton,
  Loader,
  SeeAllButton,
  TextArea,
} from 'subframe/index';
import { useState } from 'react';
import { somethingWentWrong } from 'constants/toasts';
import { getHumanReadableDateAndTime } from 'utils/formatTime';
import { AtomicTooltip } from 'components/design-system';

export default function StepDrawerCommentsForChkk(props: {
  showExampleData: boolean;
  upgrade: UpgradeTemplate;
  stage: UpgradeStage;
  step: UpgradeStageStep;
  commentThreadId?: string;
  onChange: () => void;
}) {
  const { account, user } = useUserAccountState();
  const { enqueueSnackbar } = useSnackbar();
  const [isSending, setIsSending] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [message, setMessage] = useState('');
  const [seeAllMessages, setSeeAllMessages] = useState(false);
  const { data: commentsData, mutate: commentsMutate } =
    useListUpgradeTemplateStageStepComments(
      props.upgrade.id,
      props.stage?.stage_number.toFixed(),
      props.step?.step_number.toFixed(),
      {},
      {
        request: {
          headers: { Authorization: `Bearer ${account.token}` },
        },
      },
    );

  const postComment = async (comment: string) => {
    try {
      setIsSending(true);

      await createUpgradeTemplateStageStepComment(
        props.upgrade.id,
        props.stage.stage_number.toFixed(),
        props.step.step_number.toFixed(),
        { comment: comment, is_for_chkk: true },
        {
          headers: {
            Authorization: `Bearer ${account.token}`,
          },
        },
      );

      await commentsMutate();
      await updateUpgradeTemplate(
        props.upgrade.id,
        {
          is_regenerate_pending: true,
        },
        {
          headers: {
            Authorization: `Bearer ${account.token}`,
          },
        },
      );
      props.onChange();
    } catch (err) {
      Sentry.captureException(err);
      enqueueSnackbar(
        somethingWentWrong.replace('<action>', 'posting comment'),
        { variant: 'error' },
      );
    } finally {
      setIsSending(false);
    }
  };

  const disableActionsString =
    props.upgrade.status === 'pending' ||
    props.upgrade.status === 'cancelled' ||
    props.upgrade.status === 'completed' ||
    props.upgrade.status === 'regenerate-in-progress'
      ? `The Upgrade Template is read-only and can no longer be commented on.`
      : undefined;

  const commentsList =
    commentsData?.data.filter((item) => item.is_for_chkk) || [];

  if (props.showExampleData) {
    return <></>;
  }

  return (
    <div className="flex w-full flex-col items-start gap-4 rounded-md border border-solid border-neutral-border bg-default-background shadow-sm">
      <div className="flex w-full flex-col items-start border-b border-solid border-neutral-border">
        <Accordion
          trigger={
            <div className="flex w-full items-center gap-2 border-t border-solid border-neutral-border px-6 py-5">
              <span className="grow shrink-0 basis-0 text-subheader font-subheader text-default-font">
                Leave Comment for Chkk
              </span>
              <Accordion.Chevron />
            </div>
          }
          defaultOpen={true}
        >
          <div className="flex w-full grow shrink-0 basis-0 flex-col items-start gap-4 px-6 pb-6">
            <div className="flex w-full flex-col items-start gap-4">
              {commentsData ? (
                <>
                  <div className="flex w-full items-end justify-end gap-2">
                    <div className="flex grow shrink-0 basis-0 flex-col items-start gap-4">
                      <ChatList className="h-auto w-full flex-none">
                        {commentsList
                          .slice(
                            0,
                            seeAllMessages ? commentsData.data.length : 2,
                          )
                          .map((item, idx) => {
                            return (
                              <ChatList.ChatListItem
                                key={idx}
                                name={item.user.name}
                                avatar={<Avatar image={item.user.picture} />}
                                time={getHumanReadableDateAndTime(item.time)} // same date time format as in Cluster Properties
                                message={item.comment}
                              />
                            );
                          })}
                      </ChatList>
                    </div>
                    <div className="flex w-10 flex-none flex-col items-end justify-end gap-2 px-1 py-1">
                      {commentsList.length && props.commentThreadId ? (
                        <AtomicTooltip tooltipContent="Delete the last comment">
                          <IconButton
                            icon="FeatherTrash"
                            disabled={isDeleting}
                            loading={isDeleting}
                            onClick={async () => {
                              try {
                                setIsDeleting(true);
                                await deleteUpgradeTemplateStageStepLastComment(
                                  props.upgrade.id,
                                  props.stage.stage_number.toFixed(),
                                  props.step.step_number.toFixed(),
                                  props.commentThreadId || '',
                                  {
                                    is_for_chkk: true,
                                  },
                                  {
                                    headers: {
                                      Authorization: `Bearer ${account.token}`,
                                    },
                                  },
                                );
                                await commentsMutate();
                                props.onChange();
                              } catch (err) {
                                Sentry.captureException(err);
                                enqueueSnackbar(
                                  somethingWentWrong.replace(
                                    '<action>',
                                    'deleting comment',
                                  ),
                                  { variant: 'error' },
                                );
                              } finally {
                                setIsDeleting(false);
                              }
                            }}
                          />
                        </AtomicTooltip>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  {commentsList.length > 2 && (
                    <>
                      {seeAllMessages ? (
                        <SeeAllButton
                          text="See less..."
                          onClick={() => setSeeAllMessages(false)}
                        />
                      ) : (
                        <SeeAllButton
                          text={`See ${
                            commentsList.length - 2
                          } more comments...`}
                          onClick={() => setSeeAllMessages(true)}
                        />
                      )}
                    </>
                  )}
                </>
              ) : (
                <Loader />
              )}
              <div className="flex w-full flex-col items-start gap-4">
                <div className="flex w-full flex-col items-end gap-2 py-4">
                  <div className="flex w-full items-start gap-2">
                    <Avatar image={user.profilePicture} />
                    <TextArea
                      className="h-auto grow shrink-0 basis-0"
                      label=""
                      helpText=""
                    >
                      <TextArea.Input
                        placeholder="Post comment"
                        value={message}
                        onChange={(event) => setMessage(event.target.value)}
                        onKeyUp={(event) => {
                          if (event.key === 'Enter') {
                            postComment(message);
                            setMessage('');
                          }
                        }}
                        disabled={!!disableActionsString || isSending}
                      />
                    </TextArea>
                  </div>
                  {disableActionsString ? (
                    <AtomicTooltip
                      tooltipContent={`Action not allowed. ${disableActionsString}`}
                    >
                      <Button variant="neutral-secondary" disabled={true}>
                        Comment
                      </Button>
                    </AtomicTooltip>
                  ) : (
                    <Button
                      variant="neutral-secondary"
                      disabled={!!disableActionsString || isSending}
                      loading={isSending}
                      onClick={() => {
                        message && postComment(message);
                        setMessage('');
                      }}
                    >
                      Comment
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Accordion>
      </div>
    </div>
  );
}
