import { useState } from 'react';
import { useListSHARCFlowruns } from 'api/frontend';
import useUserAccountState from 'hooks/useUserAccountState';
import { IconWithBackground } from 'subframe/components/IconWithBackground';
import { InformationalHeader } from 'subframe/components/InformationalHeader';
import {
  Avatar,
  InfoTooltip,
  LearnMoreAccordion,
  SeeAllButton,
  SkeletonText,
} from 'subframe/index';
import BaseTable from 'components/design-system/Table/BaseTable';
import { formatDistanceToNow, fromUnixTime } from 'date-fns';
import { AtomicTooltip } from 'components/design-system';
import { SHARCCheckResult, SHARCFlowRun } from 'api/models';
import { fNumber } from 'utils/formatNumber';
import MessageBox from 'components/MessageBox';
import { CopyToClipboard } from 'src/components/design-system/CopyToClipboard';
import { Icon } from '@subframe/core';
import { RouterLink } from 'components/RouterLink';
import useAccountIdRoute from 'hooks/useAccountIdRoute';
import logo from 'src/assets/images/SHARC_logo.svg';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';

interface SHARCFlowCheckResultsProps {
  SHARCFlowId: string;
}

function SHARCFlowCheckResults({ SHARCFlowId }: SHARCFlowCheckResultsProps) {
  const { account } = useUserAccountState();
  const [seeMoreRuns, setSeeMoreRuns] = useState(false);
  const [preReqOpen, setPreReqOpen] = useState(true);
  const {
    data: dataSHARCFlowRuns,
    error: errorSHARCFlowRuns,
    isLoading: isLoadingSHARCFlowRuns,
  } = useListSHARCFlowruns(
    SHARCFlowId,
    { page_size: 5 },
    {
      request: { headers: { Authorization: `Bearer ${account.token}` } },
      swr: { revalidateOnFocus: true, refreshInterval: 10 * 1000 },
    },
  );

  const allCompletedRuns = //only show completed runs
    dataSHARCFlowRuns?.data?.filter(
      (run) => run.status !== 'pending' && run.status !== 'in-progress',
    ) || [];

  const latestSuccessfulRun =
    allCompletedRuns?.length > 0 ? allCompletedRuns[0] : undefined;

  const lastRunTime = latestSuccessfulRun
    ? formatDistanceToNow(
        fromUnixTime(Number(latestSuccessfulRun?.updated || 0)),
        {
          addSuffix: true,
        },
      )
    : '-';

  if (!isLoadingSHARCFlowRuns && errorSHARCFlowRuns) {
    return (
      <MessageBox mode={'error'} message={'Error fetching SHARC Check Runs'} />
    );
  }

  const SHARCInstructionManualPath = useAccountIdRoute(
    '/orgs/:orgId/accounts/:accountId/sharc',
  );
  const { logEvent } = AnalyticsEventLogger();

  return (
    <>
      {isLoadingSHARCFlowRuns ? (
        <SkeletonText />
      ) : (
        <>
          <div className="flex w-full flex-col items-start gap-3">
            <LearnMoreAccordion
              title="Pre-requisites"
              open={preReqOpen}
              onOpenChange={() => {
                setPreReqOpen(!preReqOpen);
              }}
            >
              <span className="text-body-bold font-body-bold text-default-font">
                Ensure the SHARC CLI is installed in your cluster. Follow the
                instructions{' '}
                {
                  <RouterLink
                    to={SHARCInstructionManualPath}
                    className="hoverable-link"
                  >
                    here
                  </RouterLink>
                }{' '}
                to upgrade to the latest supported version.
              </span>
            </LearnMoreAccordion>
            <div className="flex items-center gap-2">
              <Avatar
                image={logo}
                variant="brand"
                size="large"
                square={false}
                className="custom-class bg-transparent"
              />
              <span className="text-body font-body text-default-font">
                Run the following command in your terminal with the SHARC CLI
                configured to perform Safety, Health, and Readiness Checks
                (SHARC). The results will be displayed below.
              </span>
            </div>
            <CopyToClipboard
              text={`sharc run --flow chkk://${SHARCFlowId}`}
              onCopy={() => {
                logEvent('terminal-copy-SHARC-CLI-command', {
                  command: `sharc run --flow chkk://${SHARCFlowId}`,
                });
              }}
            />
            <span className="text-body font-body text-default-font">
              Note: Results should appear shortly (in a few seconds to a minute)
              as the check completes.
            </span>
          </div>

          {allCompletedRuns?.length > 0 ? (
            <>
              <div className="flex w-full flex-col items-start gap-3 ">
                <span className="text-subheader font-subheader text-default-font">
                  Latest Run:
                </span>
                <div className="flex h-px w-full flex-none flex-col items-center gap-2 bg-neutral-200" />
                <div className="flex w-full items-start justify-between">
                  <span className="text-body font-body text-subtext-color">
                    Run ID: {latestSuccessfulRun?.id}
                  </span>
                  <span className="text-body font-body text-subtext-color">
                    Run Completed {lastRunTime}
                  </span>
                </div>
                <CheckResultInformationalHeader
                  checkRun={latestSuccessfulRun}
                />
                <BaseTable
                  data={latestSuccessfulRun?.results || []}
                  noMatchingData={!latestSuccessfulRun?.results?.length}
                  columns={[
                    {
                      id: '1',
                      title: 'Check',
                      cellType: 'cell',
                      titleStyle: { width: '25%' },
                      render: (results) => {
                        return (
                          <AtomicTooltip tooltipContent={results?.check?.name}>
                            <div className="truncate-ellipsis">
                              <span className="whitespace-normal text-body-bold font-body-bold text-neutral-700 truncate-ellipsis">
                                {results?.check?.name}
                              </span>
                            </div>
                          </AtomicTooltip>
                        );
                      },
                    },
                    {
                      id: '2',
                      title: 'Description',
                      cellType: 'cell',
                      titleStyle: { width: '60%' },
                      render: (results) => {
                        return (
                          <AtomicTooltip
                            tooltipContent={results?.check?.description}
                          >
                            <div className="truncate-ellipsis">
                              <span className="whitespace-normal text-body font-body text-neutral-500 truncate-ellipsis ">
                                {results?.check?.description}
                              </span>
                            </div>
                          </AtomicTooltip>
                        );
                      },
                    },
                    {
                      id: '3',
                      title: 'Result',
                      cellType: 'cell',

                      cellStyle: { height: 'auto' },
                      titleStyle: { width: '15%' },
                      render: (result) => {
                        return <CheckResultStatus checkResult={result} />;
                      },
                    },
                  ]}
                  actions={[]}
                />
              </div>
              {allCompletedRuns?.length > 1 && (
                <>
                  {seeMoreRuns &&
                    allCompletedRuns.slice(1, 3).map((run, idx) => {
                      const lastRunTime = latestSuccessfulRun
                        ? formatDistanceToNow(
                            fromUnixTime(Number(run?.updated || 0)),
                            {
                              addSuffix: true,
                            },
                          )
                        : '-';
                      return (
                        <div
                          className="flex w-full flex-col items-start gap-3 pt-6 pb-0"
                          key={run?.id}
                        >
                          {idx == 0 && (
                            <span className="text-subheader font-subheader text-default-font">
                              Previous Runs:
                            </span>
                          )}
                          <div className="flex h-px w-full flex-none flex-col items-center gap-2 bg-neutral-200" />
                          <div className="flex w-full items-start justify-between">
                            <span className="text-body font-body text-subtext-color">
                              Run ID: {run?.id}
                            </span>
                            <span className="text-body font-body text-subtext-color">
                              Run Completed {lastRunTime}
                            </span>
                          </div>
                          <CheckResultInformationalHeader checkRun={run} />
                          <BaseTable
                            settings={{
                              localStorageKey: 'pending_invite_table',
                              dataCyPrefix: 'pending-invite',
                            }}
                            data={run?.results || []}
                            noMatchingData={!run?.results?.length}
                            columns={[
                              {
                                id: '1',
                                title: 'Check',
                                cellType: 'cell',
                                titleStyle: { width: '25%' },
                                render: (results) => {
                                  return (
                                    <AtomicTooltip
                                      tooltipContent={results?.check?.name}
                                    >
                                      <div className="truncate-ellipsis">
                                        <span className=" truncate-ellipsis whitespace-normal text-body-bold font-body-bold text-neutral-700">
                                          {results?.check?.name}
                                        </span>
                                      </div>
                                    </AtomicTooltip>
                                  );
                                },
                              },
                              {
                                id: '2',
                                title: 'Description',
                                titleStyle: { width: '60%' },
                                cellType: 'cell',
                                render: (results) => {
                                  return (
                                    <AtomicTooltip
                                      tooltipContent={
                                        results?.check?.description
                                      }
                                    >
                                      <div className="truncate-ellipsis">
                                        <span className="truncate-ellipsis whitespace-normal text-body font-body text-neutral-500">
                                          {results?.check?.description}
                                        </span>
                                      </div>
                                    </AtomicTooltip>
                                  );
                                },
                              },
                              {
                                id: '3',
                                title: 'Result',
                                cellType: 'cell',
                                cellStyle: { height: 'auto' },
                                titleStyle: { width: '15%' },
                                render: (result) => {
                                  return (
                                    <CheckResultStatus checkResult={result} />
                                  );
                                },
                              },
                            ]}
                            actions={[]}
                          />
                        </div>
                      );
                    })}
                  <div className="pt-6">
                    <SeeAllButton
                      text={`${seeMoreRuns ? 'Hide' : 'Show'} previous runs`}
                      onClick={() => {
                        setSeeMoreRuns(!seeMoreRuns);
                        logEvent('see-previous-runs', {
                          show: seeMoreRuns ? 'hide' : 'show',
                          sharcflowId: SHARCFlowId,
                        });
                      }}
                    />
                  </div>
                </>
              )}
            </>
          ) : (
            <div
              className="flex min-h-[320px] w-full grow shrink-0 basis-0 flex-col items-center justify-center gap-2 px-1 py-1"
              data-cy="no-issues-found-message"
            >
              <Icon
                className="text-header font-header text-subtext-color"
                name="FeatherFolderX"
              />
              <span className="text-body font-body text-default-font">
                No SHARC Runs Found
              </span>
              <span className="text-body font-body text-subtext-color">
                No SHARC Flow Runs were detected. To view associated SHARC run
                results, please run the command above.
              </span>
            </div>
          )}
        </>
      )}
    </>
  );
}

const CheckResultStatus = ({
  checkResult,
}: {
  checkResult: SHARCCheckResult;
}) => {
  const getStatusIcon = (status: string) => {
    switch (status) {
      case 'pending':
        return (
          <IconWithBackground variant="neutral" icon="FeatherMoreHorizontal" />
        );

      case 'success':
        return <IconWithBackground variant="success" />;
      case 'failed':
        return <IconWithBackground variant="error" icon="FeatherX" />;
      case 'error':
        return <IconWithBackground variant="error" icon="FeatherX" />;
      case 'invalid':
        return <IconWithBackground variant="error" icon="FeatherX" />;
      default:
        return <></>;
    }
  };

  return (
    <AtomicTooltip tooltipContent={checkResult.status.status_message}>
      {getStatusIcon(checkResult.status.status)}
    </AtomicTooltip>
  );
};

const CheckResultInformationalHeader = ({
  checkRun,
}: {
  checkRun: SHARCFlowRun | undefined;
}) => {
  const latestRunPassedChecks = [];
  const latestRunFailedChecks = [];
  const latestRunPendingChecks = [];

  checkRun?.results?.forEach((result) => {
    switch (result.status.status) {
      case 'success':
        latestRunPassedChecks.push(result);
        break;
      case 'failed':
      case 'error':
        latestRunFailedChecks.push(result);
        break;
      case 'pending':
        latestRunPendingChecks.push(result);
        break;
    }
  });

  return (
    <>
      {checkRun ? (
        <InformationalHeader>
          <InformationalHeader.Item
            title="Pass"
            headingSlot={
              <InfoTooltip tooltipText="Count of checks that passed" />
            }
            bodySlot={
              <span className="text-body-bold font-body-bold text-default-font">
                {fNumber(latestRunPassedChecks?.length || 0)}
              </span>
            }
            icon={<IconWithBackground variant="success" />}
          />
          <InformationalHeader.Item
            title="Fail"
            headingSlot={
              <InfoTooltip tooltipText="Count of checks that failed or errored out" />
            }
            bodySlot={
              <span className="text-body-bold font-body-bold text-default-font">
                {fNumber(latestRunFailedChecks?.length || 0)}
              </span>
            }
            icon={<IconWithBackground variant="error" icon="FeatherX" />}
          />
          <InformationalHeader.Item
            title="Pending"
            headingSlot={
              <InfoTooltip tooltipText="Count of checks that are Pending" />
            }
            bodySlot={
              <span className="text-body-bold font-body-bold text-default-font">
                {fNumber(latestRunPendingChecks?.length || 0)}
              </span>
            }
            icon={
              <IconWithBackground
                variant="neutral"
                icon="FeatherMoreHorizontal"
              />
            }
          />
        </InformationalHeader>
      ) : (
        <></>
      )}
    </>
  );
};

export default SHARCFlowCheckResults;
