import React, { useRef, useEffect, useState } from 'react';
import { useOnScreen } from 'hooks/useOnScreen';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import {
  StepBase,
  CodeBlock,
  AccordionWithUnderline,
  Button,
} from 'subframe/index';
import { Text } from '@subframe/core';
import useAccountIdRoute from 'hooks/useAccountIdRoute';
import styles from '../AddClusterTerraformTab.module.scss';
import { RouterLink } from 'components/RouterLink';
import { Tracker } from 'components/tracker';
import { TabsWithContent } from 'components/design-system/Tabs';
import { CopyToClipboard } from 'src/components/design-system/CopyToClipboard';
import { SystemRequirements } from './SystemRequirements';
import { IngestionToken } from 'api/models';
import TokenSelector from 'components/TokenSelector';

export type AddNewTerraformClusterProps = {
  tokens: IngestionToken[];
};

export const AddNewTerraformCluster = ({
  tokens,
}: AddNewTerraformClusterProps) => {
  const ref: React.MutableRefObject<HTMLElement | undefined> = useRef();
  const isVisible = useOnScreen(ref as React.MutableRefObject<HTMLElement>);

  const [installMethod, setInstallMethod] = useState('');
  const [selectedToken, setSelectedToken] = useState<string | null>();

  const { logEvent } = AnalyticsEventLogger();

  const clusterPath = useAccountIdRoute(
    '/orgs/:orgId/accounts/:accountId/clusters',
  );

  const troubleshootPage = useAccountIdRoute(
    '/orgs/:orgId/accounts/:accountId/troubleshoot-k8s-connector?problem=configure-cluster-name-and-environment-terraform',
  );

  useEffect(() => {
    if (isVisible) {
      logEvent('add-cluster-scroll-to-bottom', {
        type: 'new-helm-cluster',
      });
    }
  }, [isVisible, logEvent]);

  const [firstOpen, setFirstOpen] = useState(true);
  return (
    <div style={{ display: 'contents' }}>
      <StepBase
        stepTitle="System Requirements"
        lastStep={false}
        stepNumber="1"
        variant="default"
        open={firstOpen}
        onOpenChange={(open) => {
          setFirstOpen(!firstOpen);
          if (open) {
            logEvent('add-cluster-terraform-step1', {
              step: 'provider-requirements',
            });
          }
        }}
        actionButtons={null}
      >
        <StepBase.Container className={styles['container-eff57117']}>
          <span className={styles['bodyBoldText']} />
          <SystemRequirements showKubectl={false} />
          <AccordionWithUnderline headerText="Provider Requirements">
            <StepBase.BulletedLine>
              hashicorp/helm: &gt;= version 2
            </StepBase.BulletedLine>
            <StepBase.BulletedLine>
              gavinbunney/kubectl: &gt;= v1.19
            </StepBase.BulletedLine>
          </AccordionWithUnderline>
        </StepBase.Container>
      </StepBase>

      <StepBase
        stepTitle="Deploy K8s Connector"
        lastStep={false}
        variant="default"
        stepNumber="2"
        actionButtons={null}
        onOpenChange={(open) => {
          if (open) {
            logEvent('add-cluster-terraform-step2', {
              step: 'Deploy-K8s-Connector',
            });
          }
        }}
      >
        <StepBase.Container className={styles['container']}>
          <div className={styles['stack-3cdada58']}>
            <div className="flex items-center gap-2">
              <Text variant="body">
                Before proceeding, select the Access Token from the dropdown
                provided:
              </Text>
              <TokenSelector
                label=""
                placeholder="Select Token"
                tokens={tokens}
                value={selectedToken!}
                onValueChange={(value: string) => {
                  setSelectedToken(value);
                }}
              />
            </div>
            <TabsWithContent
              initialTabId={installMethod || undefined}
              onTabChange={(newTabId) => {
                setInstallMethod(newTabId);
              }}
              tabs={[
                {
                  id: 'default',
                  title: 'Create new Secret and Service Account',
                  content: (
                    <div className={styles['stack-f1126c39']}>
                      <span className={styles['bodyText']}>
                        Use the following snippet in your IaC to deploy the Chkk
                        K8s Connector with a new secret:
                      </span>
                      <CopyToClipboard
                        className="sensitive select-none cursor-pointer"
                        text={`module "chkk_k8s_connector" {
  source     = "git::https://github.com/chkk-io/terraform-chkk-k8s-connector.git?ref=v0.1.3"

  create_namespace = true
  namespace        = "chkk-system"

  chkk_operator_config = {
    secret = {
      chkkAccessToken : "${selectedToken || '<ACCESS-TOKEN>'}"
    }
  }
}`}
                        singleLine={false}
                      />
                    </div>
                  ),
                },
                {
                  id: 'existing-secret',
                  title: 'Use existing Secret with new Service Account',
                  content: (
                    <div className={styles['stack-d3d77bdc']}>
                      <span className={styles['bodyText']}>
                        Create namespace:
                      </span>
                      <CopyToClipboard
                        text={`resource "kubernetes_namespace" "chkk_system" {
  metadata {
    name = "chkk-system"
  }
}`}
                        singleLine={false}
                      />
                      <span className={styles['bodyText']}>
                        To use a separately configured secret object containing
                        your Chkk access token, first create the Secret:
                      </span>
                      <CopyToClipboard
                        className="sensitive select-none cursor-pointer"
                        text={`resource "kubernetes_secret" "chkk_operator_token" {
  metadata {
    name      = "chkk-operator-token"
    namespace = "chkk-system"
  }

  data = {
    CHKK_ACCESS_TOKEN = "${selectedToken || '<ACCESS-TOKEN>'}"
  }

  type = "Opaque"
}`}
                        singleLine={false}
                      />
                      <span className={styles['bodyText']}>
                        In addition to the Secret for Chkk Operator, create a
                        Secret for Chkk Agent:
                      </span>
                      <CopyToClipboard
                        className="sensitive select-none cursor-pointer"
                        text={`resource "kubernetes_secret" "chkk_agent_token" {
  metadata {
    name      = "chkk-agent-token"
    namespace = "chkk-system"
  }

  data = {
    CHKK_ACCESS_TOKEN = "${selectedToken || '<ACCESS-TOKEN>'}"
  }

  type = "Opaque"
}`}
                        singleLine={false}
                      />
                      <span className={styles['bodyText']}>
                        With the secret created, install the Chkk Operator:
                      </span>
                      <CopyToClipboard
                        text={`module "chkk_k8s_connector" {
  source = "git::https://github.com/chkk-io/terraform-chkk-k8s-connector.git?ref=v0.1.3"

  create_namespace = false
  namespace        = "chkk-system"

  chkk_operator_config = {
    secret = {
      create = false
      ref = {
        secretName = "chkk-operator-token",
        keyName    = "CHKK_ACCESS_TOKEN"
      }
    }
  }

  chkk_agent_config = {
    secret = {
      secretName = "chkk-agent-token",
      keyName    = "CHKK_ACCESS_TOKEN"
    }
  }
}`}
                        singleLine={false}
                      />
                    </div>
                  ),
                },
                {
                  id: 'existing-service-account',
                  title: 'Use existing Secret and Service Account',
                  content: (
                    <div className={styles['stack-f1126c39']}>
                      <span className={styles['bodyText']}>
                        Create namespace:
                      </span>
                      <CopyToClipboard
                        text={`resource "kubernetes_namespace" "chkk_system" {
  metadata {
    name = "chkk-system"
  }
}`}
                        singleLine={false}
                      />
                      <span className={styles['bodyText']}>
                        To create the Service Account and Secret outside of the
                        Helm Chart, you first have to create the service account
                        for Chkk Operator with the associated permissions:
                      </span>
                      <CopyToClipboard
                        text={`resource "kubernetes_service_account" "chkk_operator" {
  metadata {
    name      = "chkk-operator"
    namespace = "chkk-system"
  }
}

resource "kubernetes_cluster_role" "chkk_operator_read_all_role" {
  metadata {
    name = "chkk-operator-read-all-role"
  }

  rule {
    api_groups = ["*"]
    resources  = ["*"]
    verbs      = ["get", "list", "watch"]
  }
}

resource "kubernetes_cluster_role_binding" "chkk_operator_rolebinding" {
  metadata {
    name = "chkk-operator-rolebinding"
  }

  role_ref {
    api_group = "rbac.authorization.k8s.io"
    kind      = "ClusterRole"
    name      = kubernetes_cluster_role.chkk_operator_read_all_role.metadata[0].name
  }

  subject {
    kind      = "ServiceAccount"
    name      = kubernetes_service_account.chkk_operator.metadata[0].name
    namespace = kubernetes_service_account.chkk_operator.metadata[0].namespace
  }
}

resource "kubernetes_cluster_role" "chkk_operator_proxy_access_role" {
  metadata {
    name = "chkk-operator-proxy-access-role"
  }

  rule {
    api_groups = ["authentication.k8s.io"]
    resources  = ["tokenreviews"]
    verbs      = ["create"]
  }

  rule {
    api_groups = ["authorization.k8s.io"]
    resources  = ["subjectaccessreviews"]
    verbs      = ["create"]
  }

  rule {
    non_resource_urls = ["/metrics"]
    verbs             = ["get"]
  }
}

resource "kubernetes_cluster_role_binding" "chkk_operator_proxy_rolebinding" {
  metadata {
    name = "chkk-operator-proxy-rolebinding"
  }

  role_ref {
    api_group = "rbac.authorization.k8s.io"
    kind      = "ClusterRole"
    name      = kubernetes_cluster_role.chkk_operator_proxy_access_role.metadata[0].name
  }

  subject {
    kind      = "ServiceAccount"
    name      = kubernetes_service_account.chkk_operator.metadata[0].name
    namespace = kubernetes_service_account.chkk_operator.metadata[0].namespace
  }
}

resource "kubernetes_role" "chkk_operator_leader_election_role" {
  metadata {
    name      = "chkk-operator-leader-election-role"
    namespace = "chkk-system"
  }

  rule {
    api_groups = [""]
    resources  = ["configmaps"]
    verbs      = ["get", "list", "watch", "create", "update", "patch", "delete"]
  }

  rule {
    api_groups = ["coordination.k8s.io"]
    resources  = ["leases"]
    verbs      = ["get", "list", "watch", "create", "update", "patch", "delete"]
  }

  rule {
    api_groups = [""]
    resources  = ["events"]
    verbs      = ["create", "patch"]
  }
}

resource "kubernetes_role_binding" "chkk_operator_leader_election_rolebinding" {
  metadata {
    name      = "chkk-operator-leader-election-rolebinding"
    namespace = "chkk-system"
  }

  role_ref {
    api_group = "rbac.authorization.k8s.io"
    kind      = "Role"
    name      = kubernetes_role.chkk_operator_leader_election_role.metadata[0].name
  }

  subject {
    kind      = "ServiceAccount"
    name      = kubernetes_service_account.chkk_operator.metadata[0].name
    namespace = kubernetes_service_account.chkk_operator.metadata[0].namespace
  }
}

resource "kubernetes_role" "chkk_operator_namespace_role" {
  metadata {
    name      = "chkk-operator-namespace-role"
    namespace = "chkk-system"
  }

  rule {
    api_groups = [""]
    resources  = ["configmaps", "pods", "events"]
    verbs      = ["create", "delete", "get", "list", "patch", "update", "watch"]
  }

  rule {
    api_groups = ["apps"]
    resources  = ["deployments"]
    verbs      = ["create", "delete", "get", "list", "patch", "update", "watch"]
  }

  rule {
    api_groups = ["batch"]
    resources  = ["cronjobs", "jobs"]
    verbs      = ["create", "delete", "get", "list", "patch", "update", "watch"]
  }

  rule {
    api_groups = ["k8s.chkk.io"]
    resources  = ["chkkagents", "chkkagents/finalizers", "chkkagents/status"]
    verbs      = ["create", "delete", "get", "list", "patch", "update", "watch", "update"]
  }
}

resource "kubernetes_role_binding" "chkk_operator_namespace_rolebinding" {
  metadata {
    name      = "chkk-operator-namespace-rolebinding"
    namespace = "chkk-system"
  }

  role_ref {
    api_group = "rbac.authorization.k8s.io"
    kind      = "Role"
    name      = kubernetes_role.chkk_operator_namespace_role.metadata[0].name
  }

  subject {
    kind      = "ServiceAccount"
    name      = kubernetes_service_account.chkk_operator.metadata[0].name
    namespace = kubernetes_service_account.chkk_operator.metadata[0].namespace
  }
}`}
                        singleLine={false}
                      />
                      <span className={styles['bodyText']}>
                        In addition to the Service Account for Chkk Operator,
                        create a Service Account for Chkk Agent:
                      </span>
                      <CopyToClipboard
                        text={`resource "kubernetes_service_account" "chkk_agent" {
  metadata {
    name      = "chkk-agent"
    namespace = "chkk-system"
  }
}

resource "kubernetes_cluster_role" "chkk_agent_read_all_role" {
  metadata {
    name = "chkk-agent-read-all-role"
  }

  rule {
    api_groups = ["*"]
    resources  = ["*"]
    verbs      = ["get", "list", "watch"]
  }
}

resource "kubernetes_cluster_role_binding" "chkk_agent_rolebinding" {
  metadata {
    name = "chkk-agent-rolebinding"
  }

  role_ref {
    api_group = "rbac.authorization.k8s.io"
    kind      = "ClusterRole"
    name      = kubernetes_cluster_role.chkk_agent_read_all_role.metadata[0].name
  }

  subject {
    kind      = "ServiceAccount"
    name      = kubernetes_service_account.chkk_agent.metadata[0].name
    namespace = kubernetes_service_account.chkk_agent.metadata[0].namespace
  }
}

resource "kubernetes_role" "chkk_agent_namespace_role" {
  metadata {
    name      = "chkk-agent-namespace-role"
    namespace = "chkk-system"
  }

  rule {
    api_groups = ["batch"]
    resources  = ["cronjobs", "jobs"]
    verbs      = ["create", "delete", "get", "list", "patch", "update", "watch"]
  }
}

resource "kubernetes_role_binding" "chkk_agent_namespace_rolebinding" {
  metadata {
    name      = "chkk-agent-namespace-rolebinding"
    namespace = "chkk-system"
  }

  role_ref {
    api_group = "rbac.authorization.k8s.io"
    kind      = "Role"
    name      = kubernetes_role.chkk_agent_namespace_role.metadata[0].name
  }

  subject {
    kind      = "ServiceAccount"
    name      = kubernetes_service_account.chkk_agent.metadata[0].name
    namespace = kubernetes_service_account.chkk_agent.metadata[0].namespace
  }
}`}
                        singleLine={false}
                      />
                      <span className={styles['bodyText']}>
                        With the Service Account setup, create secret for Chkk
                        Operator:
                      </span>
                      <CopyToClipboard
                        className="sensitive select-none cursor-pointer"
                        text={`resource "kubernetes_secret" "chkk_operator_token" {
  metadata {
    name      = "chkk-operator-token"
    namespace = "chkk-system"
  }

  data = {
    CHKK_ACCESS_TOKEN = "${selectedToken || '<ACCESS-TOKEN>'}"
  }

  type = "Opaque"
}`}
                        singleLine={false}
                      />
                      <span className={styles['bodyText']}>
                        In addition to the Secret for Chkk Operator, create a
                        Secret for Chkk Agent:
                      </span>
                      <CopyToClipboard
                        className="sensitive select-none cursor-pointer"
                        text={`resource "kubernetes_secret" "chkk_agent_token" {
  metadata {
    name      = "chkk-agent-token"
    namespace = "chkk-system"
  }

  data = {
    CHKK_ACCESS_TOKEN = "${selectedToken || '<ACCESS-TOKEN>'}"
  }

  type = "Opaque"
}`}
                        singleLine={false}
                      />
                      <span className={styles['bodyText']}>
                        With the Service Account and Secret setup, you can
                        deploy the Chkk Operator using:
                      </span>
                      <CopyToClipboard
                        text={`module "chkk_k8s_connector" {
  source = "git::https://github.com/chkk-io/terraform-chkk-k8s-connector.git?ref=v0.1.3"

  create_namespace = false
  namespace        = "chkk-system"

  chkk_operator_config = {
    secret = {
      create = false
      ref = {
        secretName = "chkk-operator-token",
        keyName    = "CHKK_ACCESS_TOKEN"
      }
    }
    serviceAccount = {
      create = false
      name   = "chkk-operator"
    }
  }

  chkk_agent_config = {
    secret = {
      secretName = "chkk-agent-token",
      keyName    = "CHKK_ACCESS_TOKEN"
    }
    serviceAccount = {
      create = false
      name   = "chkk-agent"
    }
  }
}
`}
                        singleLine={false}
                      />
                    </div>
                  ),
                },
              ]}
            />

            <Text className={styles['bodyText']}>
              For more details on the Chkk K8s Connector terraform module see
              the{' '}
              <Tracker event="terraform_documentation">
                <a
                  href={
                    'https://github.com/chkk-io/terraform-chkk-k8s-connector/blob/main/README.md'
                  }
                  className="hoverable-link"
                  target="_blank"
                  rel="noreferrer"
                >
                  documentation
                </a>
              </Tracker>
              .
            </Text>
            <Text className="text-body font-body text-default-font">
              For guidance on configuring your cluster name and environment
              during the onboarding process, refer to the{' '}
              <Tracker event="chkkagent">
                <RouterLink to={troubleshootPage} className="hoverable-link">
                  Troubleshooting doc
                </RouterLink>
              </Tracker>
              .
            </Text>
          </div>
        </StepBase.Container>
      </StepBase>

      <StepBase
        stepTitle="Verify the K8s Connector is active"
        stepNumber="3"
        onOpenChange={(open) => {
          if (open) {
            logEvent('add-cluster-terraform-step3', {
              step: 'Verify-K8s-Connector',
            });
          }
        }}
        lastStep={true}
        actionButtons={
          <RouterLink to={clusterPath}>
            <Button
              variant="brand-secondary"
              size="medium"
              data-cy="go-to-cluster-button"
              onClick={() =>
                logEvent('add-new-cluster-go-to-cluster-button-click')
              }
            >
              Go to Clusters
            </Button>{' '}
          </RouterLink>
        }
      >
        <StepBase.Container className={styles['container-479bae3c']}>
          <div className={styles['stack-9cb4dbbb']}>
            <span className={styles['bodyText']}>
              You can verify the K8s Connector has been installed in your
              cluster by running the following command:
            </span>
            <CopyToClipboard text="kubectl get chkkagent -n chkk-system" />
            <AccordionWithUnderline headerText="Sample Output:">
              <CodeBlock className="h-auto w-full flex-none">
                {`NAME           AGE
chkk-agent     65s
`}
              </CodeBlock>
            </AccordionWithUnderline>
            <div className={styles['stack-a29037e2']}>
              <span className={styles['bodyText']}>
                Your cluster should appear under{' '}
                <RouterLink
                  to={clusterPath}
                  className="hoverable-link"
                  onClick={() =>
                    logEvent('add-cluster-terraform-clusters-view-click')
                  }
                >
                  Clusters view
                </RouterLink>{' '}
                shortly (in &lt; 1 min) and Chkk will kick off a scan to detect
                Availability Risks that Need Attention in your cluster.
              </span>
              <span className={styles['bodyText']}>
                We&#39;ll also notify you via email when the first scan
                completes.
              </span>
              <span className={styles['text-3e648570']}>
                If you face any issues, please feel free to reach out to us on{' '}
                <a
                  href={'http://slack.chkk.io'}
                  className="hoverable-link"
                  target="_blank"
                  onClick={() => logEvent('add-cluster-terraform-slack-click')}
                  rel="noreferrer"
                >
                  Slack
                </a>{' '}
                or email us at{' '}
                <a
                  href="mailto:support@chkk.io"
                  className="hoverable-link"
                  onClick={() =>
                    logEvent('add-cluster-terraform-email-support-click')
                  }
                  rel="noreferrer"
                >
                  support@chkk.io
                </a>
                .
              </span>
            </div>
          </div>
        </StepBase.Container>
      </StepBase>
      <div ref={ref}></div>
    </div>
  );
};
