import React, { useEffect, useRef, useState } from 'react';
import { useOnScreen } from 'hooks/useOnScreen';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import { SystemRequirements } from './SystemRequirements';
import {
  AccordionWithUnderline,
  Button,
  CodeBlock,
  StepBase,
} from 'subframe/index';
import { CopyToClipboard } from 'src/components/design-system/CopyToClipboard';
import { Text } from '@subframe/core';
import useAccountIdRoute from 'hooks/useAccountIdRoute';
import { Stack } from 'components/utils';
import { RouterLink } from 'components/RouterLink';
import { Tracker } from 'components/tracker';
import { TabsWithContent } from 'components/design-system/Tabs';
import styles from '../AddClusterTerraformTab.module.scss';
import { IngestionToken } from 'api/models';
import TokenSelector from 'components/TokenSelector';

export type AddNewManifestClusterProps = {
  tokens: IngestionToken[];
};

export const AddNewManifestCluster = ({
  tokens,
}: AddNewManifestClusterProps) => {
  const ref: React.MutableRefObject<HTMLElement | undefined> = useRef();
  const isVisible = useOnScreen(ref as React.MutableRefObject<HTMLElement>);

  const [installMethod, setInstallMethod] = useState('');
  const [selectedToken, setSelectedToken] = useState<string | null>();

  const { logEvent } = AnalyticsEventLogger();

  const clusterPath = useAccountIdRoute(
    '/orgs/:orgId/accounts/:accountId/clusters',
  );
  const troubleshootPage = useAccountIdRoute(
    '/orgs/:orgId/accounts/:accountId/troubleshoot-k8s-connector?problem=configure-cluster-name-and-environment',
  );
  useEffect(() => {
    if (isVisible) {
      logEvent('add-cluster-scroll-to-bottom', {
        type: 'manifest-new-cluster',
      });
    }
  }, [isVisible, logEvent]);

  const [triggerOpen, setTriggerOpen] = useState({
    firstOpen: true,
    secondOpen: false,
    thirdOpen: false,
    fourthOpen: false,
    fifthOpen: false,
  });

  return (
    <div style={{ display: 'contents' }}>
      <StepBase
        data-cy="manifest-step1"
        stepTitle="System Requirements"
        stepBody=""
        stepNumber="1"
        open={triggerOpen.firstOpen}
        onOpenChange={(open) => {
          setTriggerOpen((prev) => {
            return { ...prev, firstOpen: !prev.firstOpen };
          });
          if (open) {
            logEvent('add-cluster-manifest-step1', {
              step: 'System-Requirements',
            });
          }
        }}
        actionButtons={<></>}
      >
        <StepBase.Container className={styles['container']}>
          <SystemRequirements showHelm={false} />
        </StepBase.Container>
      </StepBase>

      <StepBase
        data-cy="manifest-step2"
        stepTitle="Install the Chkk CRDs"
        stepBody=""
        stepNumber="2"
        open={triggerOpen.secondOpen}
        onOpenChange={(open) => {
          setTriggerOpen((prev) => {
            return { ...prev, secondOpen: !prev.secondOpen };
          });
          if (open) {
            logEvent('add-cluster-manifest-step2', {
              step: 'Install-Chkk-CRDs',
            });
          }
        }}
        actionButtons={<></>}
      >
        <StepBase.Container className={styles['container']}>
          <CopyToClipboard
            data-cy="manifest-install-crds"
            text={'kubectl apply -f https://helm.chkk.io/crds/crds.yaml'}
            onCopy={() =>
              logEvent('terminal-copy-add-cluster-manifest', {
                command: 'install-crds',
              })
            }
          />
        </StepBase.Container>
      </StepBase>

      <StepBase
        data-cy="manifest-step3"
        stepTitle="Deploy the Chkk Operator"
        stepBody="Execute the following commands to deploy the Chkk Operator:"
        stepNumber="3"
        open={triggerOpen.thirdOpen}
        onOpenChange={(open) => {
          setTriggerOpen((prev) => {
            return { ...prev, thirdOpen: !prev.thirdOpen };
          });
          if (open) {
            logEvent('add-cluster-manifest-step3', {
              step: 'Deploy-Chkk-Operator',
            });
          }
        }}
        actionButtons={<></>}
      >
        <StepBase.Container className={styles['container']}>
          <div className={styles['stack-f1126c39']}>
            <Text variant="body">Create Namespace:</Text>
            <CopyToClipboard
              data-cy="manifest-create-ns"
              text={'kubectl create ns chkk-system'}
              onCopy={() =>
                logEvent('terminal-copy-add-cluster-manifest', {
                  command: 'create-ns',
                })
              }
            />

            <Text variant="body">
              You can deploy Chkk Operator using Chkk Access Token by creating a
              new secret or using an existing one:
            </Text>
            <div className="flex items-center gap-2">
              <Text variant="body">
                Before proceeding, select the Access Token from the dropdown
                provided:
              </Text>
              <TokenSelector
                label=""
                placeholder="Select Token"
                tokens={tokens}
                value={selectedToken!}
                onValueChange={(value: string) => {
                  setSelectedToken(value);
                }}
              />
            </div>
            <TabsWithContent
              initialTabId={installMethod || undefined}
              onTabChange={(newTabId) => {
                setInstallMethod(newTabId);
              }}
              tabs={[
                {
                  id: 'default',
                  title: 'Create new Secret and Service Account',
                  content: (
                    <Stack spacing={4} width="fill">
                      <Text variant="body">Create Secret:</Text>
                      <CopyToClipboard
                        data-cy="manifest-create-secret"
                        className="sensitive select-none cursor-pointer"
                        text={`kubectl create secret generic chkk-operator -n chkk-system --from-literal=CHKK_ACCESS_TOKEN=${
                          selectedToken || '<ACCESS-TOKEN>'
                        }`}
                        onCopy={() => {
                          logEvent('terminal-copy-add-cluster-manifest', {
                            command: 'create-secret',
                          });
                        }}
                      />
                      <Text variant="body">Deploy the Chkk Operator:</Text>
                      <CopyToClipboard
                        data-cy="manifest-install-operator"
                        text={`kubectl apply -f https://helm.chkk.io/chkk-operator/manifest.yaml`}
                        onCopy={() =>
                          logEvent('terminal-copy-add-cluster-manifest', {
                            command: 'kubectl-apply-manifest-default',
                          })
                        }
                      />
                    </Stack>
                  ),
                },
                {
                  id: 'existing-secret',
                  title: 'Use existing Secret with new Service Account',
                  content: (
                    <div className={styles['stack-f1126c39']}>
                      <Text variant="body">
                        To use an existing secret (e.g. managed by External
                        Secrets Operator) with the Chkk K8s Connector, you need
                        to provide a reference to it as part of the operator
                        installation:
                      </Text>
                      <Text variant="body">
                        Download the Chkk K8s Operator YAML manifest:
                      </Text>
                      <CopyToClipboard
                        data-cy="manifest-download-yaml"
                        text={`wget https://helm.chkk.io/chkk-operator/manifest.yaml`}
                        onCopy={() =>
                          logEvent('terminal-copy-add-cluster-manifest', {
                            command: 'download-manifest',
                          })
                        }
                      />
                      <Text variant="body">
                        Apply the following diff to{' '}
                        <Text variant="monospace-body">manifest.yaml</Text>,
                        referencing your existing secret:
                      </Text>
                      <CodeBlock
                        data-cy="manifest-update-yaml"
                        className="h-auto w-full flex-none"
                      >
                        {[
                          '<                 name: chkk-operator',
                          '<                 key: CHKK_ACCESS_TOKEN',
                          '---',
                          '>                 name: <YOUR-SECRET>',
                          '>                 key: <YOUR-SECRET-KEY>',
                        ].join('\n')}
                      </CodeBlock>
                      <Text variant="body">
                        Deploy the Chkk Operator using updated{' '}
                        <Text variant="monospace-body">manifest.yaml</Text>:
                      </Text>
                      <CopyToClipboard
                        data-cy="manifest-install-operator-external-secret-yaml"
                        text={`kubectl apply -f manifest.yaml`}
                        onCopy={() =>
                          logEvent('terminal-copy-add-cluster-manifest', {
                            command: 'kubectl-apply-manifest-external-secret',
                          })
                        }
                      />
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </StepBase.Container>
      </StepBase>

      <StepBase
        data-cy="manifest-step4"
        stepTitle="Create a ChkkAgent Custom Resource"
        stepBody=""
        stepNumber="4"
        open={triggerOpen.fourthOpen}
        onOpenChange={(open) => {
          setTriggerOpen((prev) => {
            return { ...prev, fourthOpen: !prev.fourthOpen };
          });
          if (open) {
            logEvent('add-cluster-manifest-step4', {
              step: 'Create-ChkkAgent-Custom-Resource',
            });
          }
        }}
        actionButtons={<></>}
      >
        <StepBase.Container className={styles['container']}>
          <div className={styles['stack-f1126c39']}>
            <Text>
              You can use the following command to create a ChkkAgent resource.
              For more details on supported configurations, checkout the{' '}
              <Tracker event="chkkagent">
                <RouterLink
                  to="/chkkagent"
                  className="hoverable-link"
                  target="_blank"
                  rel="noreferrer"
                >
                  ChkkAgent documentation
                </RouterLink>
              </Tracker>
              .
            </Text>

            <TabsWithContent
              initialTabId={installMethod || undefined}
              onTabChange={(newTabId) => {
                setInstallMethod(newTabId);
              }}
              tabs={[
                {
                  id: 'default',
                  title: 'Create new Secret and Service Account',
                  content: (
                    <div className={styles['stack-f1126c39']}>
                      <Text>
                        To create ChkkAgent resource using default
                        configuration:
                      </Text>
                      <CopyToClipboard
                        data-cy="manifest-configure-chkk-agent"
                        text={[
                          'kubectl apply -f - <<EOF',
                          'apiVersion: k8s.chkk.io/v1beta1',
                          'kind: ChkkAgent',
                          'metadata:',
                          '   name: chkk-agent',
                          '   namespace: chkk-system',
                          'EOF',
                        ].join('\n')}
                        onCopy={() =>
                          logEvent('terminal-copy-add-cluster-manifest', {
                            command: 'configure-chkk-agent-default',
                          })
                        }
                      />
                    </div>
                  ),
                },
                {
                  id: 'existing-secret',
                  title: 'Use existing Secret with new Service Account',
                  content: (
                    <div className={styles['stack-f1126c39']}>
                      <Text>
                        You can modify ChkkAgent default configuration to use an
                        existing secret:
                      </Text>
                      <CopyToClipboard
                        data-cy="manifest-configure-chkk-agent"
                        text={[
                          'kubectl apply -f - <<EOF',
                          'apiVersion: k8s.chkk.io/v1beta1',
                          'kind: ChkkAgent',
                          'metadata:',
                          '   name: chkk-agent',
                          '   namespace: chkk-system',
                          'spec:',
                          '   global:',
                          '      credentials:',
                          '        accessTokenSecret:',
                          '          keyName: <YOUR-SECRET-KEY>',
                          '          secretName: <YOUR-SECRET>',
                          'EOF',
                        ].join('\n')}
                        onCopy={() =>
                          logEvent('terminal-copy-add-cluster-manifest', {
                            command: 'configure-chkk-agent-external-secret',
                          })
                        }
                      />
                    </div>
                  ),
                },
              ]}
            />
            <Text>
              For guidance on configuring your cluster name and environment
              during the onboarding process, please refer to the{' '}
              <Tracker event="chkkagent">
                <RouterLink to={troubleshootPage} className="hoverable-link">
                  Troubleshooting doc
                </RouterLink>
              </Tracker>
              .
            </Text>
          </div>
        </StepBase.Container>
      </StepBase>

      <StepBase
        stepTitle="Verify the K8s Connector is active"
        stepBody="The Chkk Operator will install the K8s Connector shortly. You can verify the K8s Connector has been installed in your cluster
            by running the following command:"
        stepNumber="5"
        lastStep={true}
        open={triggerOpen.fifthOpen}
        onOpenChange={(open) => {
          setTriggerOpen((prev) => {
            return { ...prev, fifthOpen: !prev.fifthOpen };
          });
          if (open) {
            logEvent('add-cluster-manifest-step5', {
              step: 'Verify-K8s-Connector',
            });
          }
        }}
        actionButtons={
          <RouterLink to={clusterPath}>
            <Button
              variant="brand-secondary"
              data-cy="go-to-cluster-button"
              onClick={() =>
                logEvent('add-new-cluster-go-to-cluster-button-click')
              }
              size="medium"
            >
              Go to Clusters
            </Button>
          </RouterLink>
        }
        data-cy="manifest-step5"
      >
        <StepBase.Container className={styles['container']}>
          <div className={styles['stack-f1126c39']}>
            <CopyToClipboard
              data-cy="manifest-verify-chkk-agent"
              text={`kubectl get deployment.apps/chkk-agent-manager -n chkk-system`}
              onCopy={() =>
                logEvent('terminal-copy-add-cluster-operator', {
                  command: 'verify-chkk-agent',
                })
              }
            />
            <AccordionWithUnderline headerText="Sample Output:">
              <CodeBlock
                data-cy="manifest-verify-chkk-agent-output"
                className="h-auto w-full flex-none"
              >
                {[
                  'NAME                      READY   UP-TO-DATE   AVAILABLE   AGE',
                  'chkk-agent-manager          1/1            1          4s    4s',
                ].join('\n')}
              </CodeBlock>
            </AccordionWithUnderline>
            <Text>
              Your cluster should appear under{' '}
              <RouterLink
                to={clusterPath}
                className="hoverable-link"
                onClick={() =>
                  logEvent('add-cluster-manifest-clusters-view-click')
                }
              >
                Clusters view
              </RouterLink>{' '}
              shortly {'(in < 1 min)'} and Chkk will kick off a scan to detect
              Availability Risks that Need Attention in your cluster.
            </Text>
            <Text>
              {' '}
              We&apos;ll also notify you via email when the first scan
              completes.
            </Text>
            <Text>
              If you face any issues, please feel free to reach out to us on{' '}
              <a
                href={'http://slack.chkk.io'}
                className="hoverable-link"
                target="_blank"
                onClick={() => logEvent('add-cluster-manifest-slack-click')}
                rel="noreferrer"
              >
                Slack
              </a>{' '}
              or email us at{' '}
              <a
                href="mailto:support@chkk.io"
                className="hoverable-link"
                onClick={() =>
                  logEvent('add-cluster-manifest-email-support-click')
                }
              >
                support@chkk.io
              </a>
              .
            </Text>
          </div>
        </StepBase.Container>
      </StepBase>

      <div ref={ref}></div>
    </div>
  );
};
