/* Release: 8ad4bfd8 (Latest – unreleased) */

import { useState } from 'react';
import { IconButton } from 'subframe/components/IconButton';
import { Button } from 'subframe/components/Button';
import { Dialog } from 'subframe/components/Dialog';
import styles from './UpgradesCancelDialog.module.scss';
import { AtomicTooltip } from 'components/design-system';
import { useGate } from 'statsig-react';

function UpgradesCancelDialog({
  onConfirm,
  isTemplate,
}: {
  onConfirm: () => void;
  isTemplate?: boolean;
}) {
  const [open, setOpen] = useState(false);
  const showCancelButton = useGate('show_cancel_upgrade_button');

  // Feature flag cancel icon button for Templates
  if (!showCancelButton?.value && isTemplate) {
    return <></>;
  }

  return (
    <div
      style={{
        display: 'contents',
      }}
    >
      <AtomicTooltip
        tooltipContent={`A requested Upgrade ${
          isTemplate ? 'Template' : 'Plan'
        } can be cancelled at any time during its execution. Cancelled ${
          isTemplate ? 'templates' : 'plans'
        } are still shown in the Dashboard.`}
      >
        <Button
          icon="FeatherX"
          onClick={() => setOpen(true)}
          variant="neutral-tertiary"
          className="w-full justify-start"
        >
          {`Cancel Upgrade ${isTemplate ? 'Template' : 'Plan'}`}
        </Button>
      </AtomicTooltip>

      <Dialog open={open} onOpenChange={setOpen}>
        <Dialog.Content className="flex-[0_0_auto] h-auto w-[320px]">
          <div className={styles['stack-fe8d2707']}>
            <div className={styles['stack-a5652404']}>
              <span className={styles['text-67f523a2']}>
                Cancel the Upgrade {isTemplate ? 'Template' : 'Plan'}?
              </span>
              <IconButton
                size="medium"
                icon="FeatherX"
                onClick={() => {
                  setOpen(false);
                }}
              />
            </div>
            <div className={styles['stack']}>
              <span className={styles['bodyText']}>
                Are you sure you want to cancel this upgrade{' '}
                {isTemplate ? 'template' : 'plan'}?
              </span>
              <span className={styles['bodyText']}>
                Canceling this upgrade {isTemplate ? 'template' : 'plan'} will
                discard all stages and steps completed so far. The action cannot
                be undone.
              </span>
              <span className={styles['bodyText']}>
                You will have to create a new upgrade{' '}
                {isTemplate ? 'template' : 'plan'} if you wish to continue in
                the future.
              </span>
              <div className={styles['stack-88dea843']}>
                <Button
                  size="large"
                  variant="destructive-primary"
                  icon="FeatherX"
                  onClick={() => {
                    onConfirm();
                    setOpen(false);
                  }}
                >
                  {`Cancel ${isTemplate ? 'Template' : 'Plan'}`}
                </Button>
              </div>
            </div>
          </div>
        </Dialog.Content>
      </Dialog>
    </div>
  );
}

export default UpgradesCancelDialog;
