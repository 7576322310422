'use client';

import { Button } from 'subframe/components/Button';
import { InfoTooltip } from 'subframe/components/InfoTooltip';
import { Select } from 'subframe/components/Select';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { somethingWentWrong, toastAutoHideDuration } from 'constants/toasts';
import * as Sentry from '@sentry/browser';
import { acceptInvitation } from 'api/frontend';
import { Organization, TeamInvitation } from 'api/models';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import TokenService from '@services/TokenService';
import BaseTable from 'components/design-system/Table/BaseTable';
import { useSessionStorage } from 'react-use';
import { useGetInvitationByKey } from 'api/frontend';

function AcceptInvite() {
  const [pendingInvitations, setPendingInvitations] = useSessionStorage<
    TeamInvitation[]
  >('pending_invitations', []);

  const [searchParams] = useSearchParams();

  // Get the value of the query param `id`
  const id = searchParams.get('id');
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { logEvent } = AnalyticsEventLogger();
  const { getChkkUserToken, setCurrentOrgLocalStorage, switchOrganization } =
    TokenService();
  const [isLoading, setIsLoading] = useState(false);
  const userToken = getChkkUserToken();

  const {
    data: inviteDetails,
    error,
    isLoading: isLoadingInviteDetails,
  } = useGetInvitationByKey(id || '', {
    request: {
      headers: { Authorization: `Bearer ${userToken}` },
    },
    swr: {
      enabled: !pendingInvitations || !pendingInvitations?.length, // Fetch only if pendingInvitations is empty, as this means user is already logged in and trying to open an old/expired invite
    },
  });

  const currentOrganizationStorage = JSON.parse(
    localStorage.getItem('currentOrganization') as string,
  ) as Organization | undefined;

  useEffect(() => {
    if (id) {
      const sortedInvitations = [...pendingInvitations].sort((a, b) => {
        // If a.id matches the given id, put it at the top
        if (a?.invite_key === id) return -1;
        // Otherwise, don't change the order
        if (b?.invite_key === id) return 1;
        return 0;
      });
      setPendingInvitations(sortedInvitations);
      setLandingorg({
        orgName: sortedInvitations[0]?.org_name,
        orgId: sortedInvitations[0]?.org_id,
      });
    }
  }, []);

  const handleContinue = () => {
    logEvent('accept-invite-and-return-to-existing-org', {
      previousRoute: document.referrer,
      currentOrg: currentOrganizationStorage?.id,
    });
    // Check if the previous page was from the same host (chkk webapp)
    if (
      document.referrer &&
      document.referrer.includes(window.location.hostname)
    ) {
      // If it was, navigate to previous route
      window.history.back();
    } else {
      // If not, go to the homepage
      navigate('/');
    }
  };

  const acceptInviteHandler = async (id?: string) => {
    if (id) {
      try {
        const response = await acceptInvitation(id);
      } catch (error) {
        const response = error.response;

        if (response && response?.data?.message) {
          enqueueSnackbar(response.data.message, {
            variant: 'error',
            autoHideDuration: toastAutoHideDuration,
          });
        } else {
          enqueueSnackbar(
            somethingWentWrong.replace('<action>', 'accepting invitations'),
            {
              variant: 'error',
              autoHideDuration: toastAutoHideDuration,
            },
          );
        }
        Sentry.captureException(error);
      }
    }
  };

  const acceptMultipleInvitesHandler = async () => {
    setIsLoading(true);

    try {
      const acceptPromises = pendingInvitations.map(({ invite_key }) =>
        acceptInviteHandler(invite_key),
      );

      // Wait for all the invites to be accepted
      await Promise.all(acceptPromises);

      setPendingInvitations([]);
      enqueueSnackbar('Invites accepted successfully', {
        variant: 'success',
        autoHideDuration: toastAutoHideDuration,
      });
      if (landingOrg) {
        logEvent('accept-all-invites-and-continue-to-new-org', {
          landingOrgId: landingOrg?.orgId,
          landingOrgName: landingOrg?.orgName,
        });
        const currentOrg = { id: landingOrg?.orgId } as Organization;
        setCurrentOrgLocalStorage(currentOrg);
        navigate('/');
      } else {
        handleContinue();
      }
    } catch (error) {
      enqueueSnackbar('An error occurred while accepting invites', {
        variant: 'error',
        autoHideDuration: toastAutoHideDuration,
      });
      Sentry.captureException(error, { level: 'log' });
    } finally {
      setIsLoading(false);
    }
  };

  const [landingOrg, setLandingorg] = useState(
    pendingInvitations?.length
      ? {
          orgName: pendingInvitations[0]?.org_name,
          orgId: pendingInvitations[0]?.org_id,
        }
      : {
          orgName: '',
          orgId: '',
        },
  );
  useEffect(() => {
    if (
      (!pendingInvitations || !pendingInvitations.length) &&
      !isLoadingInviteDetails
    ) {
      if (inviteDetails && inviteDetails?.status === 'ACCEPTED') {
        enqueueSnackbar('Invite already accepted', {
          variant: 'success',
          autoHideDuration: toastAutoHideDuration,
        });
        const orgId = inviteDetails?.org_id;
        logEvent('invite-accepted-already-continue-to-invite-org', {
          newOrg: orgId,
        });
        const currentOrg = { id: orgId } as Organization;
        setCurrentOrgLocalStorage(currentOrg);
        navigate('/');
      } else {
        if (error?.response?.status === 404) {
          enqueueSnackbar('Expired or Invalid invite', {
            variant: 'error',
            autoHideDuration: toastAutoHideDuration,
          });
        }
        handleContinue();
      }
    }
  }, [pendingInvitations, id, inviteDetails, error]);

  // Stop rendering if navigation has occurred
  if (!pendingInvitations || !pendingInvitations.length) {
    return null;
  }

  return (
    <div className="flex h-full w-full flex-col items-start gap-4 bg-default-background px-6 py-12">
      <div className="flex w-full grow shrink-0 basis-0 flex-col items-start gap-6 px-4 py-4">
        <div className="flex w-full items-center justify-between">
          <span className="grow shrink-0 basis-0 text-section-header font-section-header text-default-font">
            Accept Pending Invites
          </span>
        </div>
        <div className="flex w-full flex-col items-start justify-center gap-2">
          <span className="text-body font-body text-default-font">
            You&#39;ve been invited to join the following organizations. By
            clicking accept, you will become a member of all the organizations
            listed below.
          </span>

          <BaseTable
            settings={{
              localStorageKey: 'pending_invite_table',
              dataCyPrefix: 'pending-invite',
            }}
            data={pendingInvitations || []}
            noMatchingData={!pendingInvitations.length}
            columns={[
              {
                id: '1',
                title: 'Organization name',
                // titleStyle: {{ 'h-8 w-36 flex-none' }},
                cellType: 'cell',
                render: (pendingInvite) => {
                  return (
                    <span className="text-body font-body text-default-font">
                      {pendingInvite?.org_name || pendingInvite?.org_id}
                    </span>
                  );
                },
              },
              {
                id: '2',
                title: 'Inviter Email',
                titleStyle: { width: '40%' },
                cellType: 'cell',
                render: (pendingInvite) => {
                  return (
                    <span className="whitespace-nowrap text-body font-body text-neutral-500">
                      {pendingInvite?.inviter}
                    </span>
                  );
                },
              },
            ]}
            actions={[]}
          />

          <div className="flex w-full items-center gap-6">
            <div className="flex items-center gap-2">
              <span className="text-body font-body text-default-font">
                Select default landing organization
              </span>
              <InfoTooltip tooltipText="Choose which organization’s dashboard you want to land in when you accept the invites." />
            </div>
            {pendingInvitations && (
              <Select
                label=""
                placeholder={landingOrg?.orgName || 'Select organization'}
                helpText=""
                value={undefined}
                onValueChange={(value) => {
                  let selectedInvite = pendingInvitations?.filter(
                    (invite) => invite?.org_name === value,
                  )[0];
                  setLandingorg({
                    orgId: selectedInvite?.org_id,
                    orgName: selectedInvite?.org_name,
                  });
                }}
              >
                {pendingInvitations?.map((invite) => (
                  <Select.Item
                    key={invite?.org_name}
                    value={invite?.org_name || ''}
                    data-cy={`org-${invite?.org_name}`}
                  >
                    {invite?.org_name}
                  </Select.Item>
                ))}
                {currentOrganizationStorage &&
                !pendingInvitations.find(
                  (invite) => invite?.org_id === currentOrganizationStorage?.id,
                ) ? (
                  <Select.Item
                    key={currentOrganizationStorage?.name}
                    value={currentOrganizationStorage?.name || ''}
                    data-cy={`org-${currentOrganizationStorage?.name}`}
                  >
                    {currentOrganizationStorage?.name}
                  </Select.Item>
                ) : (
                  <></>
                )}
              </Select>
            )}
          </div>
          <div className="flex w-full flex-col items-start gap-2">
            <span className="text-body font-body text-default-font">
              Note: You can leave an organization anytime by navigating to
              Configure &gt; Teams and clicking Leave Team next to your email.
            </span>
          </div>
        </div>
        <div className="flex w-full items-end justify-end gap-4">
          <Button
            variant="brand-primary"
            size="large"
            onClick={() => acceptMultipleInvitesHandler()}
            loading={isLoading}
            disabled={isLoading}
          >
            Accept
          </Button>
        </div>
      </div>
    </div>
  );
}

export default AcceptInvite;
