'use client';

import React, { useEffect, useState } from 'react';
import { IconButton } from 'subframe/components/IconButton';
import { TextArea } from 'subframe/components/TextArea';
import { Button } from 'subframe/components/Button';
import { Dialog } from 'subframe/components/Dialog';
import { somethingWentWrong, toastAutoHideDuration } from 'constants/toasts';
import { useSnackbar } from 'notistack';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import useUserAccountState from 'hooks/useUserAccountState';
import { updateUpgradePlan } from 'api/frontend';
import * as Sentry from '@sentry/browser';
import { AtomicTooltip } from 'components/design-system';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import FormProvider from 'components/FormProvider';

interface SkipUpgradeAddonProps {
  open?: boolean;
  onClose?: () => void;
  upgradeId: string;
  mutatePlan: () => void;
  isFromLandingPage?: boolean;
}

function SkipUpgradeAddonDialog({
  open,
  onClose,
  upgradeId,
  mutatePlan,
  isFromLandingPage = false,
}: SkipUpgradeAddonProps) {
  const { account } = useUserAccountState();
  const { logEvent } = AnalyticsEventLogger();
  const { enqueueSnackbar } = useSnackbar();
  const [openDialog, setOpenDialog] = useState(open);

  const skipUpgradePlanSchema = Yup.object().shape({
    comment: Yup.string().max(500).optional(),
  });

  const defaultValues: { comment: string } = {
    comment: '',
  };

  const methods = useForm<{ comment: string }>({
    resolver: yupResolver(skipUpgradePlanSchema),
    defaultValues: defaultValues,
  });

  const {
    handleSubmit,
    clearErrors,
    resetField,
    formState: { isSubmitting, isValid, errors },
  } = methods;

  const SubmissionError = somethingWentWrong.replace(
    '<action>',
    'updating the Upgrade Plan as Skipped',
  );

  const SkipUpgradePlan = async (data: { comment: string }) => {
    try {
      await updateUpgradePlan(
        upgradeId,
        { status: 'skipped', status_comment: data.comment },
        {
          headers: {
            Authorization: `Bearer ${account.token}`,
          },
        },
      );
      logEvent('upgrade-plan-skipped', {
        planId: upgradeId,
      });
      enqueueSnackbar('Successfully skipped Upgrade Plan', {
        variant: 'success',
        autoHideDuration: toastAutoHideDuration,
      });
      mutatePlan();
      closeDialog();
    } catch (error) {
      Sentry.captureException(error);
      enqueueSnackbar(SubmissionError, {
        variant: 'error',
        autoHideDuration: toastAutoHideDuration,
      });
      closeDialog();
    }
  };
  if (!openDialog && errors?.comment) {
    // Reset errors when dialog is closed
    clearErrors();
  }

  const closeDialog = () => {
    onClose ? onClose() : setOpenDialog(false);
    resetField('comment');
  };

  if (open === false && openDialog === true) {
    setOpenDialog(false);
  }

  useEffect(() => {
    setOpenDialog(open);
  }, [open]);

  return (
    <div style={{ display: 'contents' }}>
      {isFromLandingPage && (
        <AtomicTooltip tooltipContent="Skip the Upgrade Plan.">
          <Button
            icon="FeatherSkipForward"
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              event.stopPropagation();
              event.preventDefault();
              setOpenDialog(true);
            }}
            variant="neutral-tertiary"
            className="w-full justify-start"
          >
            Skip Upgrade Plan
          </Button>
        </AtomicTooltip>
      )}
      <Dialog open={openDialog} onOpenChange={closeDialog}>
        <Dialog.Content className="h-auto w-auto flex-none">
          <FormProvider
            methods={methods}
            onSubmit={handleSubmit(SkipUpgradePlan)}
          >
            <div className="flex w-full min-w-[384px] max-w-[448px] flex-col items-start gap-6 pt-6 pr-6 pb-6 pl-6">
              <div className="flex w-full items-start justify-between">
                <span className="text-subheader font-subheader text-default-font">
                  Skip this Upgrade Plan?
                </span>
                <IconButton
                  size="medium"
                  icon="FeatherX"
                  onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                    closeDialog()
                  }
                />
              </div>
              <div className="flex w-full flex-col items-start gap-2">
                <span className="text-body-bold font-body-bold text-subtext-color">
                  Additional Notes (Optional)
                </span>
                <TextArea
                  className="h-auto w-full flex-none"
                  helpText={errors.comment?.message}
                  error={methods.getFieldState('comment').invalid}
                >
                  <TextArea.Input
                    className="h-auto min-h-[96px] w-full flex-none"
                    placeholder="Are there any additional details you would like to share?"
                    {...methods.register('comment')}
                  />
                </TextArea>
              </div>
              <div className="flex w-full items-end justify-end gap-4">
                <Button
                  size="large"
                  variant="neutral-tertiary"
                  icon="FeatherX"
                  onClick={() => closeDialog()}
                >
                  Cancel
                </Button>
                <Button
                  variant="brand-primary"
                  size="large"
                  icon="FeatherSkipForward"
                  onClick={handleSubmit(SkipUpgradePlan)}
                  disabled={isSubmitting}
                  loading={isSubmitting}
                >
                  Skip
                </Button>
              </div>
            </div>
          </FormProvider>
        </Dialog.Content>
      </Dialog>
    </div>
  );
}

export default SkipUpgradeAddonDialog;
